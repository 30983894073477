import { Button, Modal } from 'react-bootstrap'
import '../../pages/store/store.scss'
import React, { useState } from 'react'
import UploadImageForm from '../user/UploadImageForm'
import { SingleProduct } from '../../models/storeProduct.models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppDispatch } from '../../store/store'
import {
  createStoreProduct,
  updateSingleStoreProduct,
} from '../../store/storeProducts/storeProduct_slice'
import { AiFillDelete } from 'react-icons/ai'
import { SingleCategory } from '../../models/storeCategory.models'

interface Props {
  onHide: () => void
  show: boolean
  is_edit: boolean
  item?: SingleProduct
  storeCategory: SingleCategory[] | null
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  quantity: Yup.string().required('Quantity is required'),
  points: Yup.string().required('Points is required'),
})
function ModalStoreProduct({
  onHide,
  show,
  is_edit,
  item,
  storeCategory,
}: Props) {
  const [image, setImage] = useState<File | null>(null)
  const [showImage, setShowImage] = useState(false)

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      name: item && item.name ? item.name : '',
      description: item && item.description ? item.description : '',
      quantity: item && item.quantity ? item.quantity : 0,
      points: item && item.points ? item.points : 0,
      product_image: item && item.product_image ? item.product_image : '',
      store_category_id:
        item && item.store_category && item.store_category.id
          ? item.store_category.id
          : '',
    },
    onSubmit: (values: any) => {
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('description', values.description)
      formData.append('store_category_id', values.store_category_id)
      formData.append('quantity', values.quantity)
      formData.append('points', values.points)

      if (image) {
        formData.append('product_image', image)
      }
      if (is_edit && item && item.id) {
  formData.append('id', item.id.toString())
        dispatch(updateSingleStoreProduct(formData))
      } else {
  
        dispatch(createStoreProduct(formData)).then((res: any) => {
          onHide()
        })
      }
    },
    validationSchema,
  })

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }

  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="modal-delete" closeButton>
        <Modal.Title>
          {is_edit ? `Edit product` : 'Create new product'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-delete">
        <form
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
          className="custom-form row"
        >
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="uploadImage">
              {formik.values.product_image && !showImage ? (
                <div className="selected-image">
                  <img
                    className="addedImage"
                    src={`${
                      formik.values.product_image
                    }?timestamp=${new Date().getTime()}`}
                    alt="Product image"
                  />
                  <div
                    className="image-overlay"
                    onClick={() => {
                      setShowImage(true)
                    }}
                  >
                    <button className="uploadText delImgIcon">
                      <AiFillDelete color="red" size={25} />
                    </button>
                  </div>
                </div>
              ) : (
                <UploadImageForm image={image} setImage={setImage} />
              )}
            </div>
            <div className="form-group">
              <label htmlFor="store_category_id" className="custom-label">
                Category
              </label>
              <select
                name="store_category_id"
                id="store_category_id"
                value={formik.values.store_category_id}
                onChange={formik.handleChange}
                className="custom-input"
              >
                <option value="" hidden>
                  Select Category
                </option>
                {storeCategory?.map(item => (
                  <option
                    style={{ background: 'black' }}
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="name" className="custom-label">
                Product name
              </label>
              <input
                name="name"
                id="name"
                className="custom-input"
                placeholder="Enter product name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error">{formik.errors.name.toString()}</div>
              )}
            </div>
            <div className="pointsQuantityField">
              <div className="form-group">
                <label htmlFor="points" className="custom-label">
                  Points
                </label>
                <input
                  name="points"
                  id="points"
                  className="custom-input"
                  placeholder="Enter points"
                  type="number"
                  value={formik.values.points}
                  onChange={formik.handleChange}
                />
                {formik.touched.points && formik.errors.points && (
                  <div className="error">{formik.errors.points.toString()}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="quantity" className="custom-label">
                  Quantity
                </label>
                <input
                  name="quantity"
                  id="quantity"
                  className="custom-input"
                  placeholder="Enter quantity"
                  type="number"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                />
                {formik.touched.quantity && formik.errors.quantity && (
                  <div className="error">
                    {formik.errors.quantity.toString()}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="description" className="custom-label">
                Description
              </label>
              <input
                name="description"
                id="description"
                className="custom-input"
                placeholder="Enter a description"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="error">
                  {formik.errors.description.toString()}
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-delete">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={(e: any) => {
            handleSubmit(e)
          }}
          variant="danger"
        >
          {is_edit ? 'Edit' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalStoreProduct
