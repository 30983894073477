import axios from 'axios'
import { storeCategoryList } from '../../models/storeCategory.models'
import api from '../interceptors/api'

//Get all store Categories
const getAllStoreCategory = async () => {
  const response = await api.get(`/store-categories`)
  return response.data as storeCategoryList
}

const deleteSingleStoreCategory = async (id: number) => {
  const response = await api.delete(`/store-categories/${id}`)
  return id
}

const updateSingleStoreCategory = async (data: any) => {
  const id = data.get('id')
   const response = await api.patch(`/store-categories/${id}`, data)
 
  return response.data
}

const createStoreCategory = async (data: any) => {
  const response = await api.post(`/store-categories`, data)

  return response.data
}
const storeCategoryService = {
  getAllStoreCategory,
  deleteSingleStoreCategory,
  updateSingleStoreCategory,
  createStoreCategory,
}

export default storeCategoryService
