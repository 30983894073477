import { useAppSelector } from '../../store/store'
import { RxAvatar } from 'react-icons/rx'
import { Winner } from '../../models/leaderboard.models'
import '../../pages/leaderboard/leaderboard.scss'

function Winners() {
  const { leaderboard } = useAppSelector(state => state.leaderboard)

  return (
    <>
      <div className="container px-4 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-row-reverse justify-content-center align-items-center">
          {leaderboard?.winners && leaderboard.winners.length > 0 ? (
            leaderboard.winners.map((item: Winner, index: number) => {
              if (item.rank === 2) {
                return (
                  <div className="AvatarNameMain" key={index}>
                    <div className="AvatarBlueB">
                      <div className="AvatarBlackB">
                        {item.user.profile_image ? (
                          <img
                            className="secAvatarImg"
                            src={`${
                              item.user.profile_image
                            }?timestamp${new Date().getTime()}`}
                          />
                        ) : (
                          <RxAvatar className="secAvatarImg" />
                        )}
                      </div>
                      <div className="rankMainUsers">
                        {item.rank ? item.rank : '/'}
                      </div>
                    </div>
                    <div className="pointsNameMainBox">
                      <div className="pointsXpBox">
                        <div className="points">
                          {item.points ? item.points : '/'}
                        </div>
                        <div className="xp">XP</div>
                      </div>
                      <div className="name">
                        {item.user.name ? item.user.name : '/'}
                      </div>
                    </div>
                  </div>
                )
              } else if (item.rank === 1) {
                return (
                  <div className="AvatarNameMain2" key={index}>
                    <div className="centeredAvatarBg">
                      <div className="AvatarBlueB">
                        <div className="AvatarBlackB">
                          {item.user.profile_image ? (
                            <img
                              className="centeredAvatarImg"
                              src={`${
                                item.user.profile_image
                              }?timestamp${new Date().getTime()}`}
                            />
                          ) : (
                            <RxAvatar className="centeredAvatarImg" />
                          )}
                        </div>
                        <div className="rankMainUsers2">
                          {item.rank ? item.rank : '/'}
                        </div>
                      </div>
                    </div>
                    <div className="pointsNameMainBox">
                      <div className="pointsXpBox">
                        <div className="points">10,380</div>
                        <div className="xp">XP</div>
                      </div>
                      <div className="name">
                        {item.user.name ? item.user.name : '/'}
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="AvatarNameMain3" key={index}>
                    <div className="AvatarBlueB">
                      <div className="AvatarBlackB">
                        {item.user.profile_image ? (
                          <img
                            className="secAvatarImg"
                            src={`${
                              item.user.profile_image
                            }?timestamp${new Date().getTime()}`}
                          />
                        ) : (
                          <RxAvatar className="secAvatarImg" />
                        )}
                      </div>
                      <div className="rankMainUsers3">
                        {item.rank ? item.rank : '/'}
                      </div>
                    </div>
                    <div className="pointsNameMainBox">
                      <div className="pointsXpBox">
                        <div className="points">3,404</div>
                        <div className="xp">XP</div>
                      </div>
                      <div className="name">
                        {item.user.name ? item.user.name : '/'}
                      </div>
                    </div>
                  </div>
                )
              }
            })
          ) : (
            <div className="text-center text-white-50">
              Oops! It seems like there are no leaderboards at the moment.
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Winners
