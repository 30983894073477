import React, { useState } from 'react'
import { BiSolidEdit } from "react-icons/bi"
import { AiFillDelete } from "react-icons/ai"
import { RiFolderVideoFill } from "react-icons/ri"
import DeleteModal from '../shared/DeleteModal'
import { Link } from 'react-router-dom';
import { Item } from '../../models/quest.model'


interface Props {
    id: number;
    deleteFunction: (id: number) => any;
    item: Item
    page: number
  }
function QuestTableActions({id, deleteFunction, item, page}:Props) {
    const [showModal, setShowModal] = useState(false)
    
  return (
   <React.Fragment>
     <div className='loader-container'>
        <Link to={`/edit-quest/${id}`} state={{item, page}} ><BiSolidEdit color='green' size={25} /></Link>
        <button onClick={() => setShowModal(true)}><AiFillDelete color='red' size={25} /></button>
        <Link to={`/quest-details/${id}`} > <RiFolderVideoFill size={25} color='blue' />  </Link>
    </div>

    {showModal && <DeleteModal id={id} deleteFunction={deleteFunction} show={showModal} onHide={() => setShowModal(false)} />}
   </React.Fragment>
  )
}

export default QuestTableActions