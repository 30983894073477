import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import storeProductService from './storeProduct_service'
import { Data, SingleProduct } from '../../models/storeProduct.models'

interface InitialStateStoreProduct {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  storeProduct: Data | null
  url: string | null
  SingleProduct: SingleProduct | null
  successCRUD: boolean
}

const initialStoreProductState: InitialStateStoreProduct = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  storeProduct: null,
  url: null,
  successCRUD: false,
  SingleProduct: null,
}

//Get all store products
export const getAllStoreProduct = createAsyncThunk(
  'storeProduct/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await storeProductService.getAllStoreProduct(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Delete single store product
export const deleteSingleStoreProduct = createAsyncThunk(
  'storeProduct/delete-single-product',
  async (id: number, thunkAPI) => {
    try {
      return await storeProductService.deleteSingleStoreProduct(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Update single store product
export const updateSingleStoreProduct = createAsyncThunk(
  'storeProduct/update',
  async (data: any, thunkAPI) => {
    try {
      return await storeProductService.updateSingleStoreProduct(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Create New store product
export const createStoreProduct = createAsyncThunk(
  'storeProduct/new-product',
  async (data: any, thunkAPI) => {
    try {
      return await storeProductService.createStoreProduct(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const storeProductSlice = createSlice({
  name: 'storeProduct',
  initialState: initialStoreProductState,
  reducers: {
    resetStoreProductState(state) {
      state.isSuccess = false
      state.isError = false
      state.successCRUD = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllStoreProduct.pending, state => {
        state.isLoading = true
        state.storeProduct = null
      })
      .addCase(getAllStoreProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.storeProduct = action.payload.data
      })
      .addCase(getAllStoreProduct.rejected, state => {
        state.storeProduct = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteSingleStoreProduct.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(deleteSingleStoreProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.storeProduct = state.storeProduct && {
          ...state.storeProduct,
          items: state.storeProduct?.items?.filter(
            x => x.id !== +action.payload
          ),
        }
        state.successCRUD = true
      })
      .addCase(deleteSingleStoreProduct.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreProduct.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.successCRUD = true
      })
      .addCase(updateSingleStoreProduct.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })

      .addCase(createStoreProduct.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(createStoreProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.successCRUD = true
      })
      .addCase(createStoreProduct.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
  },
})

export const { resetStoreProductState } = storeProductSlice.actions
export default storeProductSlice.reducer
