import api from '../interceptors/api'

//Get leaderboard
const getLeaderboard = async (id: any, limit: number) => {
  const response = await api.get(
    `/challenges/leaderboard/${id}?&limit=${limit}`
  )
  return response.data.data
}
const leaderboardService = {
  getLeaderboard,
}

export default leaderboardService
