import React from 'react'
import { RxAvatar } from 'react-icons/rx'

interface Props {
    post: any
}

function DuelPost({post}: Props) {
  return (
    <div className="duel-post position-relative">
    <div className="w-100 position-relative">
      <div className="w-100 d-flex ">
        <img
          src={post.thumbnail_url}
          alt="image"
          className="duel-image"
        />
      </div>

      <img
        src={post.thumbnail_url}
        alt="image"
        className="background"
      />

{post.user.profile_image ?      <img
        src={post.user.profile_image}
        alt=""
        className="user-image"
      /> :    <RxAvatar className="user-image" size={40} />}
      <p className="user-name">
        {post.user.name || post.user.username}
      </p>
    </div>
  </div>
  )
}

export default DuelPost