import React, { useState } from 'react'
import './login.scss'
import LoginCode from '../../components/login/LoginCode'
import LoginForm from '../../components/login/LoginForm'
import { useAppSelector } from '../../store/store'
import countryCodes from '../../countryCodes.json'


function Login() {
  const { isEmailValid, isAdmin, isLoading } = useAppSelector(
    state => state.auth
  )
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [country_code, setCountry_code] = useState(countryCodes.countries[0].dial_code)

  return (
    <div className="container" style={{ minHeight: '100vh' }}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '100vh' }}
      >
        <div className="card py-3 px-2">
          {isEmailValid ? (
            <LoginCode
              isLoading={isLoading}
              isAuth={isAdmin}
              email={email}
              phone={phone}
              countryCode={country_code}
            />
          ) : (
            <LoginForm
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              countryCode={country_code}
              setCountryCode={setCountry_code}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Login
