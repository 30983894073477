import { wait } from '@testing-library/user-event/dist/utils'
import api from '../interceptors/api'

// Get duels for arena
const getDuelsForArena = async (data: any) => {
  const response = await api.post('/challenges/index/v2', data)
  return response.data
}

//Generate duels
const generateDuelsForArena = async (data: any) => {
  const response = await api.post('/challenges/testkreacija', data)
  return response.data
}

const duelsService = {
  getDuelsForArena,
  generateDuelsForArena,
}

export default duelsService
