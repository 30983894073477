import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TagsInput from '../../../components/quest/TagsInput'
import LoadSpinner from '../../../components/shared/LoadSpinner'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import QuestVideoUploadForm from '../../../components/quest/quest-video/QuestVideoUploadForm'
import { createNewPostToJoinQuest } from '../../../store/quests/quest-video/quest-video_slice'
import { getAllUsers } from '../../../store/users/user_slice'
import { User } from '../../../models/shared.models'

function JoinQuest() {
  const { id } = useParams()
  const [tags, setTags] = useState<any>([])
  const { isLoading, url } = useAppSelector(state => state.questVideo)
  const { isLoading: userLoading, users } = useAppSelector(state => state.users)
  const dispatch = useAppDispatch()
  const [allUsers, setAllUsers] = useState<any>([])
  const [name, setName] = useState('')
  const [page, setPage] = useState(1)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>(null)

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    onSubmit: (values: any) => {
      values.content_type = 1
      values.tags = tags
      if (id) {
        values.arena_id = +id
      }

      if(selectedUser){
        values.userId = selectedUser.id;
      }

      dispatch(createNewPostToJoinQuest(values))
    },
  })

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }

  useEffect(() => {
    let data = {
      search: name,
      page: page,
    }
    dispatch(getAllUsers(data)).then((res: any) => {
      setAllUsers(
        res.payload.data.items && res.payload.data.items
          ? [...allUsers, ...res.payload.data.items]
          : []
      )
    })
  }, [page])

  const serachByName = (value: string) => {
    setName(value)
    let data: any = {
      search: value,
    }
    dispatch(getAllUsers(data)).then((res: any) => {
      setAllUsers(res.payload.data.items && res.payload.data.items)
    })
  }

  return (
    <>
      <div className="container">
        <form style={{ maxWidth: 500 }} className="row">
          <div className="form-group">
            <label htmlFor="title" className="custom-label">
              Title
            </label>
            <input
              name="title"
              id="title"
              disabled={url !== null}
              className="custom-input"
              placeholder="Enter a title"
              type="text"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label htmlFor="description" className="custom-label">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                className="custom-input"
                disabled={url !== null}
                rows={6}
                placeholder="Enter a description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group position-relative">
              <label className="custom-label ">User</label>
              <div
                className="custom-input cursor-pointer"
                onClick={() => setShowOptions(!showOptions)}
              >
                {selectedUser
                  ? selectedUser.name ||
                    selectedUser.username ||
                    (selectedUser.emails.length > 0 &&
                      selectedUser.emails[0].email) ||
                    (selectedUser.phones.length > 0 &&
                      selectedUser.phones[0].phone)
                  : 'Select user'}
              </div>
              {showOptions && (
                <div className="custom-input option-warpper">
                  <input
                    type="text"
                    placeholder="Enter a value to serach"
                    value={name}
                    onChange={e => serachByName(e.target.value)}
                    className="custom-input"
                  />
                  {allUsers &&
                    allUsers.map((user: any) => (
                      <div
                        onClick={() => {
                          setSelectedUser(user)
                          setShowOptions(false)
                        }}
                        key={user.id}
                        className="select-option cursor-pointer"
                      >
                        {user.name ||
                          user.username ||
                          (user.emails.length > 0 && user.emails[0].email) ||
                          (user.phones.length > 0 && user.phones[0].phone)}
                      </div>
                    ))}

                  {users &&
                    users.meta.totalPages > 1 &&
                    users.meta.currentPage < users.meta.totalPages && (
                      <p
                        onClick={e => {
                          setPage(users.meta.currentPage + 1)
                        }}
                        className="text-center pt-3 cursor-pointer load-more-hover"
                      >
                        Load more
                      </p>
                    )}
                </div>
              )}
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label className="custom-label">Tags</label>
              <TagsInput
                disabled={url !== null}
                tags={tags}
                setTags={setTags}
              />
            </div>
          </div>

          {url === null && (
            <div className="form-group">
              <button
                disabled={isLoading}
                onClick={e => handleSubmit(e)}
                type="button"
                className="save-button"
              >
                {isLoading && url === null ? (
                  <LoadSpinner
                    width={15}
                    height={15}
                    loadMessage={'Joining quest... '}
                  />
                ) : (
                  'Join quest'
                )}
              </button>
            </div>
          )}
        </form>

        {url && <QuestVideoUploadForm />}
      </div>
    </>
  )
}

export default JoinQuest
