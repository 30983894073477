import { Link } from "react-router-dom";

interface Props {
  activeName: string;
  breadcrumbs?: { name: string; path: string }[];
}

function BreadCrumbs({ activeName, breadcrumbs }: Props) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link  to="/">Home</Link>
        </li>
        {breadcrumbs &&
          breadcrumbs.map((item: { name: string; path: string }, index) => (
            <li key={index} className="breadcrumb-item">
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))}
        <li className="breadcrumb-item active text-white">{activeName}</li>
      </ol>
    </nav>
  );
}

export default BreadCrumbs;
