import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store';
import { uploadVideoForQuest } from '../../store/quests/quest_slice';
import LoadSpinner from '../shared/LoadSpinner';

function UploadVideoForm() {
    const { url, isLoading, uploadVideoSuccess } = useAppSelector(
        (state) => state.quests
      );
      const dispatch = useAppDispatch();
      const [video, setVideo] = useState<any>(null);
      const [selectedFileName, setSelectedFileName] = useState(null);

      const uploadVideo = (e: any) => {
        e.preventDefault();
        let data = new FormData();
        data.append("video", video);
        const decodedurl = url && JSON.parse(url);
        if (decodedurl && decodedurl.uploadURL) {
          data.append("url", decodedurl.uploadURL);
          dispatch(uploadVideoForQuest(data));
        }
      };
    
      const onVideoChange = (e: any) => {
        setVideo(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
          setSelectedFileName(file.name);
        } else {
          setSelectedFileName(null);
        }
      };
  return (
    <form className="custom-form" onSubmit={uploadVideo}>
    <div className="form-group">
      <label className="custom-file-label">
        <input
          type="file"
          id="fileInput"
          accept=".mp4"
          onChange={(e) => onVideoChange(e)}
        />
        Choose a video
      </label>
      <div className="selected-file-name">
        {selectedFileName && `Selected File: ${selectedFileName}`}
      </div>

      <button
        disabled={isLoading || !video || uploadVideoSuccess}
        type="submit"
        className="custom-button"
      >
        {isLoading ? (
          <LoadSpinner
            width={15}
            height={15}
            loadMessage="Uploading video... "
          />
        ) : (
          "Upload Video"
        )}
      </button>

    
    </div>
  </form>
  )
}

export default UploadVideoForm