export const QuestDetailsTitles = [
"Videos for quest ",
"Duels for quest ",
"Join to quest "
]


export const QuestDetailsBradcrumbs = [
    "Quest videos",
    "Duels",
    "Join quest"
]