import Pagination from "./Pagination";
import LoadSpinner from "./LoadSpinner";
import { Meta } from "../../models/shared.models";

interface Props {
  header: any;
  data?: any;
  switchFunction?: (data: any) => any;
  isLoading: boolean;
  meta?: Meta;
  setPage?: (page: number) => any
  path?: string
  searchData?: any
}

function CustomTable({ header, data, switchFunction, isLoading, meta, setPage, path, searchData }: Props) {

  return (
    <div className="container table-responsive p-4">
      <table className="table custom-border table-striped table-dark table-hover">
        <thead style={{ backgroundColor: "#404438" }}>
          <tr>
            {header}
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
      {isLoading && <LoadSpinner width={50} height={50} />}

      {meta && path && meta.itemCount >0 && setPage && switchFunction && !isLoading && (
        <Pagination path={path} setPage={setPage} meta={meta} switchFunction={switchFunction} data={searchData} />
      )}
    </div>
  );
}

export default CustomTable;
