import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  generateDuelsForArena,
  getAllDuelsForQuest,
  resetDuelsState,
} from '../../../store/duels/duels_slice'
import DuelVideo from '../../../components/quest/duels/DuelVideo'
import LoadSpinner from '../../../components/shared/LoadSpinner'
import Pagination from '../../../components/shared/Pagination'
import { getAllVideosForQuest } from '../../../store/quests/quest-video/quest-video_slice'
import { toast } from 'react-toastify'
import { getSingleArena } from '../../../store/quests/quest_slice'

function QuestDuels() {
  const { duels, isLoading } = useAppSelector(state => state.duels)
  const { questVideos } = useAppSelector(state => state.questVideo)
  const { singleQuest } = useAppSelector(state => state.quests)

  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const dispatch = useAppDispatch()
  const { id } = useParams()
  let data = {
    limit: 20,
    page: page,
    arena_id: id ? +id : null,
  }

  const generateDuels = () => {
    if (id && singleQuest) {
      dispatch(
        generateDuelsForArena({
          post_id: singleQuest.post_id.id,
          challenge_parent_type_id: +id,
        })
      ).then((res: any) => {
        if (res.payload.status === true) {
          toast.success(`Generated duels`)
          window.location.reload()
        }
      })
    
  }
  }

  useEffect(() => {
    if (id) {
      dispatch(resetDuelsState())
      dispatch(getAllDuelsForQuest(data))
      dispatch(getSingleArena(id))
      if (!questVideos) {
        const dataToSend = { ...data, challenge_parent_type_id: +id }
        dispatch(getAllVideosForQuest(dataToSend))
      }
    }
  }, [id])

  if (isLoading) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />
  }

  return (
    <div className="container">
      <button onClick={() => generateDuels()} className="add-new-button mb-3">
        Generate
      </button>
      <div className="row">
        {duels &&
          duels.data.items.map((duel: any, index: number) => (
            <DuelVideo duels={duel} key={index} />
          ))}
      </div>

      {duels?.data?.meta && !isLoading && duels.data.meta.itemCount > 0 && (
        <Pagination
          path={`quest-details/${id}`}
          setPage={setPage}
          meta={duels.data.meta}
          switchFunction={getAllDuelsForQuest}
          data={data}
        />
      )}
    </div>
  )
}

export default QuestDuels
