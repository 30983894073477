import { useAppSelector } from '../../store/store'
import { RxAvatar } from 'react-icons/rx'
import { Participant } from '../../models/leaderboard.models'
import '../../pages/leaderboard/leaderboard.scss'

function Participants() {
  const { leaderboard } = useAppSelector(state => state.leaderboard)
  return (
    <>
      <div>
        {leaderboard?.participants && leaderboard.participants.length > 0
          ? leaderboard.participants.map((item: Participant, index: number) => (
              <>
                <div className="secMain" key={index}>
                  <div className="otherRanksMain">
                    <div className="avatarNamePointsMain">
                      <div className="smallAvatarBlueB">
                        <div className="smallAvatarBlackB">
                          {item.user.profile_image ? (
                            <img
                              className="smallAvatar"
                              src={`${
                                item.user.profile_image
                              }?timestamp${new Date().getTime()}`}
                            />
                          ) : (
                            <RxAvatar className="smallAvatar" />
                          )}
                        </div>
                      </div>
                      <div className="smallNamePoints">
                        <div className="smallName">
                          {item.user.name ? item.user.name : '/'}
                        </div>
                        <div className="smallPoints">
                          {item.points ? item.points : '/'} pts
                        </div>
                      </div>
                    </div>
                    <div className="smallRanks">
                      {item.rank ? item.rank : '/'}
                    </div>
                  </div>
                </div>
              </>
            ))
          : null}
      </div>
    </>
  )
}

export default Participants
