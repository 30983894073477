import React, { useState } from 'react'
import '../../pages/login/login.scss'
import OTPInput from './OTPInput'
import { useAppDispatch } from '../../store/store'
import {
  userEmailVerifyOtp,
  userPhoneVerifyOtp,
} from '../../store/login/login_slice'

interface Props {
  email: string
  phone: string
  isAuth: boolean | null
  isLoading: boolean
  countryCode: string
}

function LoginCode({ email, phone, isAuth, isLoading, countryCode }: Props) {
  const dispatch = useAppDispatch()
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: ''
  })

  const handleInputChange = (inputId: string, value: number) => {
    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [inputId]: value,
    }))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const otp =
      inputValues.input1 +
      inputValues.input2 +
      inputValues.input3 +
      inputValues.input4 +
      inputValues.input5 + 
      inputValues.input6

    if (phone !== '') {
      const data = {
        mobile: phone,
        otp,
        verification_type: 4,
        country_code: countryCode,
      }
      dispatch(userPhoneVerifyOtp(data))
    } else {
      const data = {
        email,
        otp,
        verification_type: 2,
        source: 'web',
        device_token: Date.now().toString(),
      }
      dispatch(userEmailVerifyOtp(data))
    }
  }
  return (
    <React.Fragment>
      <p className="text-center mb-3 mt-2">ENTER THE CODE</p>

      <div className="division">
        <div className="row">
          <div className="col-3">
            <div className="line l"></div>
          </div>
          <div className="col-6">
            <span>Please enter the code sent to: {email || phone}</span>
            {isAuth === false && (
                <p className="text-danger">Access denied.</p>
              )}
          </div>
          <div className="col-3">
            <div className="line r"></div>
          </div>
        </div>
      </div>

      <div
        id="otp"
        className="inputs d-flex flex-row justify-content-center mt-2"
        data-autosubmit="true"
      >
        <OTPInput
          id="input1"
          value={inputValues.input1}
          onValueChange={handleInputChange}
          previousId={null}
          handleSubmit={handleSubmit}
          nextId="input2"
        />
        <OTPInput
          id="input2"
          value={inputValues.input2}
          onValueChange={handleInputChange}
          previousId="input1"
          handleSubmit={handleSubmit}
          nextId="input3"
        />
        <OTPInput
          id="input3"
          value={inputValues.input3}
          onValueChange={handleInputChange}
          previousId="input2"
          handleSubmit={handleSubmit}
          nextId="input4"
        />
        <OTPInput
          id="input4"
          value={inputValues.input4}
          onValueChange={handleInputChange}
          previousId="input3"
          handleSubmit={handleSubmit}
          nextId="input5"
        />

<OTPInput
          id="input5"
          value={inputValues.input5}
          onValueChange={handleInputChange}
          previousId="input4"
          handleSubmit={handleSubmit}
          nextId="input6"
        />

<OTPInput
          id="input6"
          value={inputValues.input6}
          onValueChange={handleInputChange}
          previousId="input5"
          handleSubmit={handleSubmit}
          nextId="input7"
        />

      </div>
    </React.Fragment>
  )
}

export default LoginCode
