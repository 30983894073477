import {
  Categories,
  ChildCategory,
  ParentCategory,
} from '../../models/categories.models'
import { Button, Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppDispatch } from '../../store/store'
import {
  addNewCategory,
  editCategory,
} from '../../store/categories/categories_slice'

interface Props {
  category?: ChildCategory
  onHide: () => void
  show: boolean
  is_edit: boolean
  categories: Categories
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

function ModalCategory({ category, onHide, show, is_edit, categories }: Props) {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      name: category && category.name ? category.name : '',
      description: category && category.description ? category.description : '',
      category_parent_id:
        category?.category_parent_id && category.category_parent_id
          ? category.category_parent_id
          : categories.data[0].id,
      is_popular: category && category.is_popular ? category.is_popular : false,
    },
    onSubmit: (values: any) => {
      values.category_parent_id = parseInt(values.category_parent_id)

      if (is_edit) {
        values.id = category?.id
        dispatch(editCategory(values))
      } else {
        dispatch(addNewCategory(values)).then((res: any) => {
          onHide()
        })
      }
    },
    validationSchema,
  })

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }
  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="modal-delete" closeButton>
        <Modal.Title>
          {is_edit ? `Edit category #${category?.id}` : 'Create new category'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-delete">
        <form
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
          className="custom-form row"
        >
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="form-group">
              <label htmlFor="name" className="custom-label">
                Name
              </label>
              <input
                name="name"
                id="name"
                className="custom-input"
                placeholder="Enter a title"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error">{formik.errors.name.toString()}</div>
              )}
            </div>

            <div className="col-xl-12 col-md-12 mb-4">
              <div className="form-group">
                <label htmlFor="description" className="custom-label">
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  className="custom-input"
                  rows={6}
                  placeholder="Enter a description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="col-xl-12 col-md-12 mb-4">
              <div className="form-group">
                <label htmlFor="category_parent_id" className="custom-label">
                  Parent category
                </label>
                <select
                  name="category_parent_id"
                  id="category_parent_id"
                  className="custom-input"
                  value={formik.values.category_parent_id}
                  onChange={formik.handleChange}
                >
                  {categories &&
                    categories.data.map((parent: ParentCategory) => (
                      <option
                        style={{ background: 'black' }}
                        key={parent.id}
                        value={parent.id}
                      >
                        {parent.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-xl-6 col-md-12 mb-4">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={formik.handleChange}
                    checked={formik.values.is_popular}
                    id="is_popular"
                    name="is_popular"
                  />
                  <label
                    className="custom-label"
                    htmlFor="is_popular"
                    style={{ marginTop: '4px' }}
                  >
                    Popular
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-delete">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={(e: any) => {
            handleSubmit(e)
          }}
          variant="danger"
        >
          {is_edit ? 'Edit' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCategory
