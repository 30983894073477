import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../../components/shared/BreadCrumbs'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import TagsInput from '../../../components/quest/TagsInput'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { formatTags } from '../../../models/quest.model'
import { AiFillDelete, AiFillTwitterCircle } from 'react-icons/ai'
import DeleteModal from '../../../components/shared/DeleteModal'
import {
  deleteVideoFromQuest,
  getSingleVideo,
  updateVideoFromQuest,
} from '../../../store/quests/quest-video/quest-video_slice'
import { toast } from 'react-toastify'
import LoadSpinner from '../../../components/shared/LoadSpinner'
import { BsFacebook } from 'react-icons/bs'
import { BiLogoInstagramAlt } from 'react-icons/bi'

function VideoDetails() {
  const { isLoading, isCDSuccess } = useAppSelector(state => state.questVideo)
  const location = useLocation()
  const { videoItem, page } = (location && location.state) || {}
  const { id, videoId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { questSingleVideo } = useAppSelector(state => state.questVideo)

  const [item, setItem] = useState(videoItem ? videoItem : questSingleVideo)

  const [showModal, setShowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tags, setTags] = useState<any>(
    item && item.tags ? formatTags(item.tags) : []
  )

  const breadCrumbs = [
    { name: `Videos for quest #${id}`, path: `/quest-details/${id}` },
  ]

  const formik = useFormik({
    initialValues: {
      description: item && item.description ? item.description : '',
      title: item && item.title ? item.title : '',
      tags: [],
    },
    onSubmit: (values: any) => {
      values.tags = tags

      if (isEdit) {
        values.id = videoId
        dispatch(updateVideoFromQuest(values))
      }
    },
  })

  useEffect(() => {
    if (!item && videoId) {
      dispatch(getSingleVideo(videoId))
    }

    if (questSingleVideo) {
      setItem(questSingleVideo)
    }
  }, [questSingleVideo])

  useEffect(() => {
    if (isCDSuccess) {
      toast.success('Success.')
      navigate(`/quest-details/${id}?page=${page ? page : 1}`)
    }
  }, [isCDSuccess])

  if (isLoading && !questSingleVideo && !item) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />;
  }

  return (
    <>
      {item && (
        <>
          <div className="d-flex justify-content-between mb-5 mt-3 align-items-center">
            <h1 className="white">Video #{videoId}</h1>

            <BreadCrumbs
              activeName={`Video #${videoId}`}
              breadcrumbs={breadCrumbs}
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-md-6 mb-4">
                <ReactPlayer
                  url={item.post_url}
                  width="100%"
                  height="100%"
                  controls={true}
                  
                />
              </div>

              <div className="col-xl-8 col-md-6 mb-4">
                <form>
                  <div className="row">
                    <div className="col-10 mb-4">
                      {isEdit ? (
                        <h2 className="text-center text-primary">
                          Edit your video #{videoId}
                        </h2>
                      ) : (
                        <button
                          type="button"
                          className="custom-button"
                          onClick={() => setIsEdit(true)}
                        >
                          Edit video #{videoId}
                        </button>
                      )}
                    </div>

                    <div className="col-2 mb-4 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="delete-button"
                      >
                        <AiFillDelete color="red" size={30} />
                      </button>
                    </div>

                    <div className="col-xl-6 col-md-12 mb-4">
                      <div className="form-group">
                        <label htmlFor="title" className="custom-label">
                          Title
                        </label>
                        <input
                          name="title"
                          id="title"
                          disabled={!isEdit || isLoading}
                          className="custom-input"
                          placeholder="Enter a title"
                          type="text"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-xl-12 col-md-12 mb-4">
                      <div className="form-group">
                        <label htmlFor="description" className="custom-label">
                          Description
                        </label>
                        <textarea
                          disabled={!isEdit || isLoading}
                          name="description"
                          id="description"
                          className="custom-input"
                          rows={6}
                          placeholder="Enter a description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-xl-12 col-md-12 mb-4">
                      <div className="form-group">
                        <label className="custom-label">Tags</label>
                        <TagsInput
                          disabled={!isEdit || isLoading}
                          tags={tags}
                          setTags={setTags}
                        />
                      </div>
                    </div>

                    <div className="col-xl-4 col-md-12 mb-4">
                      <div className="form-group">
                        <label className="custom-label">Upload date: </label>
                        <p className="additional-information-videos">
                          {moment(item.created_at).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-md-12 mb-4">
                      <div className="form-group">
                        <label className="custom-label">
                          Number of views:{' '}
                        </label>
                        <p className="additional-information-videos">0</p>

                        <label className="custom-label">
                          Number of likes:{' '}
                        </label>
                        <p className="additional-information-videos">
                          {item.likes_count}
                        </p>

                        <label className="custom-label">
                          Total number of votes:{' '}
                        </label>
                        <p className="additional-information-videos">
                          {item.votes_count}
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-md-12 mb-4">
                      <div className="form-group">
                        <label className="custom-label">
                          Total number of share:
                        </label>
                        <p className="additional-information-videos">
                          {item.share_count}
                        </p>

                        <label className="custom-label">
                          Number of share on social media:
                        </label>
                        <div className="d-flex justify-content-between alignt-items-center">
                          <div className="d-flex">
                            <BsFacebook
                              className="me-2"
                              size={25}
                              color="white"
                              opacity={0.7}
                            />
                            <p className="custom-label">0</p>
                          </div>
                          <div className="d-flex">
                            <BiLogoInstagramAlt
                              className="me-2"
                              size={25}
                              color="white"
                              opacity={0.7}
                            />
                            <p className="custom-label">0</p>
                          </div>
                          <div className="d-flex">
                            <AiFillTwitterCircle
                              className="me-2"
                              size={25}
                              color="white"
                              opacity={0.7}
                            />
                            <p className="custom-label">0</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-12 mb-4">
                      <div className="form-group">
                        <label className="custom-label">Thumbnail image </label>
                        <div className="d-flex">
                          <img
                            src={item.thumbnail_url}
                            style={{
                              width: '100px',
                              height: '150px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-12 mb-4 d-flex  align-items-end ">
                      {isEdit && (
                        <button
                          type="button"
                          onClick={(e: any) => formik.handleSubmit(e)}
                          className="custom-button"
                        >
                          {isLoading ? (
                            <LoadSpinner
                              width={15}
                              height={15}
                              loadMessage={`Updating video #${videoId}... `}
                            />
                          ) : (
                            'Update video'
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {showModal && (
            <DeleteModal
              id={item.id}
              deleteFunction={deleteVideoFromQuest}
              show={showModal}
              onHide={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </>
  )
}

export default VideoDetails
