import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportTypes } from "../../../models/reports.models";
import reportTypesService from "./report-types_service";

interface InitialStateReportTypes {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  reportTypes: ReportTypes | null;
  successCRUD: boolean;
}

const initialReportTypesState: InitialStateReportTypes = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  reportTypes: null,
  successCRUD: false,
};

//Get all report types
export const getAllReportsTypes = createAsyncThunk(
  "report-types/get-all",
  async (_, thunkAPI) => {
    try {
      return await reportTypesService.getAllReportsTypes();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const reportTypesSlice = createSlice({
  name: "report-types",
  initialState: initialReportTypesState,
  reducers: {
    resetReportTypesState(state) {
      state.isSuccess = false;
      state.successCRUD = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReportsTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllReportsTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportTypes = action.payload;
      })
      .addCase(getAllReportsTypes.rejected, (state) => {
        state.reportTypes = null;
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { resetReportTypesState } = reportTypesSlice.actions;
export default reportTypesSlice.reducer;
