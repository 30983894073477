import { useLocation, useParams } from "react-router-dom";
import QuestForm from "../../components/quest/QuestForm";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useEffect } from "react";
import { getSingleArena } from "../../store/quests/quest_slice";
import LoadSpinner from "../../components/shared/LoadSpinner";

function EditQuestPage() {
  const { id } = useParams();
  const location = useLocation();
  const { item, page } = location && location.state || {};
  const {singleQuest, isLoading} = useAppSelector((state) => state.quests)
  const dispatch = useAppDispatch()


  useEffect(() => {
    if(!item && id){
        dispatch(getSingleArena(id))
    }
  }, [])

  if (isLoading && !singleQuest && !item) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />;
  }


  return <>{id && (item || singleQuest) && <QuestForm page={page} is_edit={true} data={item ? item : singleQuest} id={id} />}</>;
}

export default EditQuestPage;
