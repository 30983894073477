import { useEffect, useState } from 'react'
import CustomTable from '../../components/shared/CustomTable'
import StoreTableCategoryAction from '../../components/store/StoreTableCategoryAction'
import ModalStoreCategory from '../../components/store/ModalStoreCategory'
import './store.scss'
import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  deleteSingleStoreCategory,
  getAllStoreCategory,
  resetStoreCategoryState,
} from '../../store/storeCategories/storeCategory_slice'
import { useSearchParams } from 'react-router-dom'
import { SingleCategory } from '../../models/storeCategory.models'
import { RxAvatar } from 'react-icons/rx'
import { toast } from 'react-toastify'

function StoreCategoriesListPage() {
  const { storeCategory, isLoading, successCRUD } = useAppSelector(
    state => state.storeCategory
  )
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [name, setName] = useState('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (successCRUD) {
      toast.success('Success')
      dispatch(getAllStoreCategory())
      dispatch(resetStoreCategoryState())
    }
  }, [successCRUD])

  useEffect(() => {
    dispatch(getAllStoreCategory())
  }, [dispatch])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">Store Categories List</h1>
        <button
          onClick={() => setShowModalCreate(true)}
          className="add-new-button"
        >
          Add new category
        </button>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-4 searchBar">
          <input
            name="name"
            id="name"
            className="custom-input"
            placeholder="Search by category name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <CustomTable
        path="store-categories"
        isLoading={isLoading}
        switchFunction={getAllStoreCategory}
        header={<HeaderTable />}
        data={
          <StoreCategoriesData
            storeCategory={storeCategory}
            isLoading={isLoading}
            searchQuery={name}
          />
        }
      />

      {showModalCreate && storeCategory && (
        <ModalStoreCategory
          is_edit={false}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </>
  )
}

export default StoreCategoriesListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Image</th>
      <th>Category name</th>
      <th>Description</th>
      <th>Products</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const StoreCategoriesData = (props: any) => {

  const filteredCategories = props && props.storeCategory ? props.storeCategory.filter((x:any) => x.name.includes(props.searchQuery.trim(''))) : []
  return (
    <>
      {filteredCategories &&
     filteredCategories.length > 0 &&
      !props.isLoading ? (
        filteredCategories.map((item: SingleCategory, index: number) => (
          <tr key={index}>
            <td className="align-middle">{item.id}</td>
            <td className="align-middle">
              {item.category_image ? (
                <img
                  className="storeAvatar"
                  src={`${
                    item.category_image
                  }?timestamp${new Date().getTime()}`}
                  alt={item.name}
                />
              ) : (
                <RxAvatar size={50} />
              )}
            </td>
            <td className="align-middle">{item.name ? item.name : '/'}</td>
            <td className="align-middle">
              {item.description ? item.description : '/'}
            </td>
            <td className="align-middle">
              {item.product_count ? item.product_count : '/'}
            </td>
            <td className="align-middle text-center">
              <StoreTableCategoryAction
                item={item}
                id={item.id}
                deleteFunction={deleteSingleStoreCategory}
                page={props.page}
              />
            </td>
          </tr>
        ))
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={6} className="text-center text-white-50">
                <h6 className="mb-0">No matching categories found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
