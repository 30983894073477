import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Item, QuestVideos } from "../../../models/quest-video.model";
import questVideosService from "./quest-video_service";

interface InitialStateQuestVideos {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  questVideos: QuestVideos | null;
  questSingleVideo: Item | null
  isCDSuccess: boolean
  url: string | null
  uploadVideoSuccess: boolean
  id: null


}

const initialQuestVideosState: InitialStateQuestVideos = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  questVideos: null,
  isCDSuccess: false,
  questSingleVideo: null,
  url: null,
  uploadVideoSuccess: false,
  id: null

};

//Create post to join quest
export const createNewPostToJoinQuest = createAsyncThunk(
  'quest-video/create-post',
  async (data: any, thunkAPI) =>{
    try {
      return await questVideosService.createPostToJoinQuest(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)

    }
  }
)

//Upload video
export const uploadVideoToJoinQuest = createAsyncThunk(
  'quest-videos/upload-video',
  async (data: any, thunkAPI) => {
    try {
      return await questVideosService.uploadVideo(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Get all videos for quest
export const getAllVideosForQuest = createAsyncThunk(
  "quest-videos/get-all",
  async (data: any, thunkAPI) => {
    try {
      return await questVideosService.getVideosForArena(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


//Get single video 
export const getSingleVideo = createAsyncThunk(
  "quest-videos/get-single-video",
  async (id: any, thunkAPI) => {
    try {
      return await questVideosService.getSingleVideo(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Delete video from quest
export const deleteVideoFromQuest = createAsyncThunk(
  "quest-videos/delete",
  async (id: number, thunkAPI) => {
    try {
      return await questVideosService.deleteVideoFromQuest(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


//Update video from quest
export const updateVideoFromQuest = createAsyncThunk(
  "quest-videos/update",
  async (data: any, thunkAPI) => {
    try {
      return await questVideosService.updateVideo(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const questVideosSlice = createSlice({
  name: "quest-videos",
  initialState: initialQuestVideosState,
  reducers: {
    resetQuestVideoState(state) {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.isCDSuccess = false
    },
    resetDeleteSuccess(state) {
      state.isCDSuccess = false

    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createNewPostToJoinQuest.pending, state => {
      state.isLoading = true
    })
    .addCase(createNewPostToJoinQuest.fulfilled, (state, action) => {
      state.isLoading = false
      state.url = action.payload.data.url
      state.id = action.payload.data.post.id
    })
    .addCase(createNewPostToJoinQuest.rejected, state => {
      state.isSuccess = false
      state.isLoading = false
      state.isError = true
      state.url = null
      // state.id = null;
    })

    .addCase(uploadVideoToJoinQuest.pending, state => {
      state.isLoading = true
    })
    .addCase(uploadVideoToJoinQuest.fulfilled, (state, action) => {
      state.isLoading = false
      state.uploadVideoSuccess = true
    })
    .addCase(uploadVideoToJoinQuest.rejected, state => {
      state.isSuccess = false
      state.isLoading = false
      state.isError = true
    })
      .addCase(getAllVideosForQuest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllVideosForQuest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.questVideos = action.payload;
      })
      .addCase(getAllVideosForQuest.rejected, (state, action) => {
        state.questVideos = null;
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(deleteVideoFromQuest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVideoFromQuest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCDSuccess = true;
        state.isError = false;
        state.questVideos = state.questVideos && {
          ...state.questVideos,
          data: {
            ...state.questVideos.data,
            items: state.questVideos.data.items.filter(
              (x) => x.id !== action.payload
            ),
          },
        };
      })
      .addCase(deleteVideoFromQuest.rejected, (state, action) => {
        state.isCDSuccess = false;
        state.isLoading = false;
        state.isError = true;
      })



      .addCase(updateVideoFromQuest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVideoFromQuest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCDSuccess = true;
        state.isError = false;
       
      })
      .addCase(updateVideoFromQuest.rejected, (state, action) => {
        state.isCDSuccess = false;
        state.isLoading = false;
        state.isError = true;
      })


      .addCase(getSingleVideo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questSingleVideo = action.payload;
        state.isError = false;
       
      })
      .addCase(getSingleVideo.rejected, (state, action) => {
        state.questSingleVideo = null;
        state.isLoading = false;
        state.isError = true;
      })
  },
});

export const { resetQuestVideoState, resetDeleteSuccess } = questVideosSlice.actions;
export default questVideosSlice.reducer;
