import React, { useState } from 'react'
import { BiSolidEdit } from 'react-icons/bi'
import { IoBan } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { SingleUser } from '../../models/users.model'
import DeactivateModal from '../shared/DeactivateModal'

interface Props {
  id: number
  deleteFunction: (id: number) => any
  item: SingleUser
  page: number
}
function UserTableActions({ id, deleteFunction, item, page }: Props) {
  const [showModal, setShowModal] = useState(false)

  return (
    <React.Fragment>
      <div className="loader-container">
        {item && item.deletedAt === null ? (
          <>
            <Link to={`/edit-user/${id}`} state={{ item, page }}>
              <BiSolidEdit color="green" size={25} />
            </Link>
            <button onClick={() => setShowModal(true)}>
              <IoBan color="red" size={24} />
            </button>
          </>
        ) : (
          '/'
        )}
      </div>

      {showModal && (
        <DeactivateModal
          id={id}
          deleteFunction={deleteFunction}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default UserTableActions
