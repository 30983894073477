import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import leaderboardService from './leaderboard_service'
import { Data, Winner, Participant } from '../../models/leaderboard.models'

interface InitialStateLeaderboard {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  leaderboard: Data | null
  url: string | null
  winner: Winner | null
  participant: Participant | null
}

const initialLeaderboardState: InitialStateLeaderboard = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  leaderboard: null,
  url: null,
  winner: null,
  participant: null,
}

//Get leaderboard
export const getLeaderboard = createAsyncThunk(
  'leaderboard/get-leaderboard',
  async ({ id, limit }: { id: any; limit: number }, thunkAPI) => {
    try {
      return await leaderboardService.getLeaderboard(id, limit)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: initialLeaderboardState,
  reducers: {
    resetLeaderboardState(state) {
      state.isSuccess = false
      state.isError = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLeaderboard.pending, state => {
        state.isLoading = true
      })
      .addCase(getLeaderboard.fulfilled, (state, action) => {
        state.isLoading = false
        state.leaderboard = action.payload
      })
      .addCase(getLeaderboard.rejected, state => {
        state.leaderboard = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
  },
})

export const { resetLeaderboardState } = leaderboardSlice.actions
export default leaderboardSlice.reducer
