import { Button, Modal } from 'react-bootstrap'
import '../../pages/store/store.scss'
import { useFormik } from 'formik'
import { useAppDispatch } from '../../store/store'
import { updateSingleStoreOrder } from '../../store/storeOrders/storeOrder_slice'
import { SingleOrder } from '../../models/storeOrder.models'

interface Props {
  onHide: () => void
  show: boolean
  item?: SingleOrder
}
function ModalStoreOrder({ onHide, show, item }: Props) {

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      status:
        item && item.status
          ? item.status === 'completed'
            ? 'completed'
            : 'pending'
          : '',
    },
    onSubmit: (values: any) => {
      const formData = new FormData()
      const statusToSend =
        values.status === 'completed' ? 'completed' : 'pending'
      formData.append('status', statusToSend)
      const orderItem = { values, formData }
      if (item?.id) {
        values.id = item.id
        dispatch(updateSingleStoreOrder(orderItem))
      }
    },
  })

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }
  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="modal-delete" closeButton>
        <Modal.Title>Edit order</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-delete">
        <form
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
          className="custom-form row"
        >
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="form-group">
              <label htmlFor="status" className="custom-label">
                Status
              </label>
              <select
                name="status"
                id="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                className="custom-input"
              >
                <option value="" hidden>
                  Select Category
                </option>
                <option style={{ background: 'black' }} value={'completed'}>
                  Completed
                </option>
                <option style={{ background: 'black' }} value={'pending'}>
                  Pending
                </option>
              </select>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-delete">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={(e: any) => {
            handleSubmit(e)
          }}
          variant="danger"
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalStoreOrder
