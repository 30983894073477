import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Categories, ParentCategory } from '../../models/categories.models'
import categoryService from './categories_service'

interface InitialStateCategory {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  categories: Categories | null
  successCRUD: boolean
}

const initialQuestState: InitialStateCategory = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  categories: null,
  successCRUD: false,
}

//Get all categories
export const getAllCategories = createAsyncThunk(
  'categories/get-all',
  async (_, thunkAPI) => {
    try {
      return await categoryService.getAllCategories()
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Delete category by id
export const deleteCategoryById = createAsyncThunk(
  'categories/delete',
  async (id: number, thunkAPI) => {
    try {
      return await categoryService.deleteCategoryById(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Add new  category
export const addNewCategory = createAsyncThunk(
  'categories/add-new',
  async (data: any, thunkAPI) => {
    try {
      return await categoryService.addNewCategory(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Edit category
export const editCategory = createAsyncThunk(
  'categories/edit',
  async (data: any, thunkAPI) => {
    try {
      return await categoryService.editCategory(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialQuestState,
  reducers: {
    resetcategoryState(state) {
      state.isSuccess = false
      state.successCRUD = false
      state.isError = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllCategories.pending, state => {
        state.isLoading = true
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.categories = action.payload
      })
      .addCase(getAllCategories.rejected, state => {
        state.categories = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(deleteCategoryById.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(deleteCategoryById.fulfilled, (state, action) => {
        state.isLoading = false

        state.categories = state.categories && {
          ...state.categories,
          data: state.categories.data.filter(
            (parentCategory: ParentCategory) => {
              const index = parentCategory.children.findIndex(
                x => x.id === action.payload
              )
              if (index !== -1) {
                const parent = {
                  ...parentCategory,
                  children: parentCategory.children.splice(index, 1),
                }
                return parent
              } else {
                return parentCategory
              }
            }
          ),
        }

        state.successCRUD = true
      })
      .addCase(deleteCategoryById.rejected, state => {
        state.categories = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })

      .addCase(addNewCategory.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(addNewCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.categories = state.categories && {
          ...state.categories,
          data: state.categories.data.filter(
            (parentCategory: ParentCategory) => {
              if (parentCategory.id === action.payload.category_parent_id) {
                const parent = {
                  ...parentCategory,
                  children: parentCategory.children.push(action.payload),
                }
                return parent
              } else {
                return parentCategory
              }
            }
          ),
        }
        state.successCRUD = true
      })
      .addCase(addNewCategory.rejected, state => {
        state.categories = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })

      .addCase(editCategory.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        state.isLoading = false
        // state.categories = state.categories && {
        //   ...state.categories,
        //   data: state.categories.data.filter(
        //     (parentCategory: ParentCategory) => {
        //       const is_parent_change = parentCategory.children.find(
        //         x => x.id === action.payload.id
        //       )
        //       if (
        //         parentCategory.id === action.payload.category_parent_id &&
        //         is_parent_change?.category_parent_id ===
        //           action.payload.category_parent_id
        //       ) {
        //         const index = parentCategory.children.findIndex(
        //           x => x.id === action.payload.id
        //         )

        //         let child = parentCategory.children

        //         child[index] = action.payload

        //         const parent = {
        //           ...parentCategory,
        //           children: child,
        //         }
        //         return parent
        //       } else {
                
        //         return parentCategory
        //       }
        //     }
        //   ),
        // }
        state.successCRUD = true
      })
      .addCase(editCategory.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
  },
})

export const { resetcategoryState } = categoriesSlice.actions
export default categoriesSlice.reducer
