import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import './sidebar.scss'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'
import { useAppDispatch } from '../../store/store'
import { logout } from '../../store/login/login_slice'

function Sidebar() {
  const [active, setActive] = useState(true)

  const location = useLocation()
  const dispatch = useAppDispatch()

  const activeUserPaths = ['/users', '/new-user', '/edit-user/']
  const activeQuestPaths = [
    '/quests-list',
    '/new-quest',
    '/edit-quest/',
    '/quest-details/',
  ]
  const activeReportPaths = ['/reports', '/reports/']

  return (
    <nav id="sidebar" className={active ? '' : 'active'}>
      <div className="custom-menu">
        <button
          onClick={() => setActive(!active)}
          type="button"
          id="sidebarCollapse"
          className="btn btn-primary"
        >
          <GiHamburgerMenu />
        </button>
      </div>
      <div className="p-4 pt-5 sidebar-flex">
        <div>
          <Link to={'./'} className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <ul className="list-unstyled components mb-5">
            <li className={location.pathname === '/dashboard' ? 'active' : ''}>
              <Link to="/" className="nav-link">
                Dashboard
              </Link>
            </li>
            <li
              className={location.pathname === '/leaderboard' ? 'active' : ''}
            >
              <Link to="/leaderboard" className="nav-link">
                Leaderboard
              </Link>
            </li>
            <li
              className={
                activeUserPaths.some(path => location.pathname.includes(path))
                  ? 'active'
                  : ''
              }
            >
              <Link to="/users" className="nav-link">
                Users
              </Link>
            </li>
            <li
              className={
                activeQuestPaths.some(path => location.pathname.includes(path))
                  ? 'active'
                  : ''
              }
            >
              <Link to="/quests-list" className="nav-link">
                Quests List
              </Link>
            </li>
            <li
              className={
                location.pathname === '/categories-list' ? 'active' : ''
              }
            >
              <Link to="/categories-list" className="nav-link">
                Categories List
              </Link>
            </li>
            <li
              className={
                activeReportPaths.some(path => location.pathname.includes(path))
                  ? 'active'
                  : ''
              }
            >
              <Link to="/reports" className="nav-link">
                Report List
              </Link>
            </li>
            <li
              className={
                location.pathname === '/store-categories' ? 'active' : ''
              }
            >
              <Link to="/store-categories" className="nav-link">
                Store Categories
              </Link>
            </li>
            <li
              className={
                location.pathname === '/store-products' ? 'active' : ''
              }
            >
              <Link to="/store-products" className="nav-link">
                Store Products
              </Link>
            </li>
            <li
              className={location.pathname === '/store-orders' ? 'active' : ''}
            >
              <Link to="/store-orders" className="nav-link">
                Store Orders
              </Link>
            </li>
          </ul>
        </div>

        <button
          onClick={() => {
            dispatch(logout())
          }}
          className="button-logout"
        >
          Logout
        </button>
      </div>
    </nav>
  )
}

export default Sidebar
