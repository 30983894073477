import axios from 'axios'
import { storeProductList } from '../../models/storeProduct.models'
import api from '../interceptors/api'

//Get all store products
const getAllStoreProduct = async (data: any = 1) => {
  const response = await api.post(`/products/index`, data)
  return response.data as storeProductList
}

const deleteSingleStoreProduct = async (id: number) => {
  const response = await api.delete(`/products/${id}`)
  return id
}

const updateSingleStoreProduct = async (data: any) => {
  const id = data.get('id')
  const response = await api.patch(`/products/${id}`, data)

  return response.data
}

const createStoreProduct = async (data: any) => {
  const response = await api.post(`/products`, data)
  return response.data
}
const storeProductService = {
  getAllStoreProduct,
  deleteSingleStoreProduct,
  updateSingleStoreProduct,
  createStoreProduct,
}

export default storeProductService
