import { useState } from 'react'
import { hashtagsList } from '../../data/hashtags'

interface Props {
  tags: []
  setTags: (e: any) => void
  disabled: boolean
}

function TagsInput({ tags, setTags, disabled }: Props) {
  const [suggestedTags, setSuggestedTags] = useState<string[]>([])
  const [value, setValue] = useState('')

  function handleKeyDown(e: any, word?: string) {
    if (word) {
      setSuggestedTags([])
      setTags([...tags, `#${word}`])
      setValue('')
    }
    if (e.key !== 'Enter') return
    const value = `#${e.target.value}`
    if (!value.trim()) return
    setTags([...tags, value])
    setValue('')
    setSuggestedTags([])
  }

  function removeTag(index: number) {
    setTags(tags.filter((el: any, i: any) => i !== index))
  }

  const suggestTags = (e: any) => {
    const value = e.target.value

    const suggest = hashtagsList.filter((word: string) => {
      if (word.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
        return word
      }
    })

    if (e.target.value) {
      setSuggestedTags(suggest)
    } else {
      setSuggestedTags([])
    }
  }

  return (
    <div className="tags-input-container">
      <div className="tags-input-wrap position-relative">
        <input
          onChange={(e: any) => {
            setValue(e.target.value)
            suggestTags(e)
          }}
          onKeyDown={handleKeyDown}
          type="text"
          value={value}
          className="tags-input"
          placeholder="Type tags and press enter"
          disabled={disabled}
        />
        {suggestedTags.length > 0 && (
          <div className="w-100 suggest-tags">
            <ul>
              {suggestedTags.map((word: string, index: number) => (
                <li
                  onClick={e => handleKeyDown(e, word)}
                  key={index}
                  className="text-white"
                >
                  {word}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="tags-input-content">
        {tags.map((tag: string, index: number) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            {!disabled && (
              <span className="close" onClick={() => removeTag(index)}>
                &times;
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TagsInput
