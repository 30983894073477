import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div style={{ height: "100vh" }}>
      <div className="h-full d-flex flex-column align-items-center justify-content-center g-0">
        <div className="alert alert-danger w-full text-center" role="alert">
          Something went wrong!
        </div>
        <Link
          to="/"
          className="btn d-block"
          role="button"
          style={{ width: "fit-content", height: "fit-content" }}
        >
          Dashboard
        </Link>
      </div>
    </div>
  );
};

export default Error404;
