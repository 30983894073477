import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import duelsService from './duels_services'

interface InitialStateDuels {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  duels: any | null
  generateSuccess: boolean
}

const initialDuelsState: InitialStateDuels = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  duels: null,
  generateSuccess: false,
}

//Get all duels
export const getAllDuelsForQuest = createAsyncThunk(
  'quest-duels/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await duelsService.getDuelsForArena(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Generate duels for arena
export const generateDuelsForArena = createAsyncThunk(
  'quest-duels/generate-duels',
  async (data: any, thunkAPI) => {
    try {
      return await duelsService.generateDuelsForArena(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const duelsSlice = createSlice({
  name: 'quest-duels',
  initialState: initialDuelsState,
  reducers: {
    resetDuelsState(state) {
      state.isSuccess = false
      state.isError = false
      state.generateSuccess = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllDuelsForQuest.pending, state => {
        state.isLoading = true
      })
      .addCase(getAllDuelsForQuest.fulfilled, (state, action) => {
        state.isLoading = false
        state.duels = action.payload
      })
      .addCase(getAllDuelsForQuest.rejected, state => {
        state.duels = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
      .addCase(generateDuelsForArena.fulfilled, (state, action) => {
        state.generateSuccess = action.payload.status
      })
      .addCase(generateDuelsForArena.rejected, state => {
        state.duels = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.generateSuccess = false
      })
  },
})

export const { resetDuelsState } = duelsSlice.actions
export default duelsSlice.reducer
