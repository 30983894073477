import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";


const data = [
  {
    date: "10/10/2023",
    votes: 50,
  },
  {
    date: "11/10/2023",
    votes: 30,
  },
  {
    date: "12/10/2023",
    votes: 70,
  },
  {
    date: "13/10/2023",
    votes: 100,
  },
  {
    date: "14/10/2023",
    votes: 20,
  },
  {
    date: "15/10/2023",
    votes: 0,
  },
  {
    date: "16/10/2023",
    votes: 58,
  }
];

function ChartForVotes() {
    return (
      <ResponsiveContainer width="100%" height="80%">

      <LineChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="votes"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
    </LineChart>
    </ResponsiveContainer>
      );
}

export default ChartForVotes