import { useLocation, useParams } from 'react-router-dom'
import UserForm from '../../components/user/UserForm'
import { useEffect } from 'react'
import { getSingleUser } from '../../store/users/user_slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import LoadSpinner from '../../components/shared/LoadSpinner'

function EditUserPage() {
  const { id } = useParams()
  const location = useLocation()
  const { item, page } = (location && location.state) || {}
  const dispatch = useAppDispatch()
  const { singleUser, isLoading } = useAppSelector(state => state.users)

  useEffect(() => {
    if (!item && id) {
      dispatch(getSingleUser(id))
    }
  }, [])

  if (isLoading && !singleUser && !item) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />
  }

  return (
    <>
      {id && (item || singleUser) && (
        <UserForm
          page={page}
          is_edit={true}
          data={item ? item : singleUser}
          id={id}
        />
      )}
    </>
  )
}

export default EditUserPage
