import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import questsService from './quest_service'
import {
  CreateQuestFormValues,
  Data,
  Item,
  
} from '../../models/quest.model'
import { act } from 'react-dom/test-utils'

interface InitialStateQuests {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  quests: Data | null
  url: string | null
  uploadVideoSuccess: boolean
  singleQuest: Item | null
  id: null
}

const initialQuestState: InitialStateQuests = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  quests: null,
  url: null,
  uploadVideoSuccess: false,
  singleQuest: null,
  id: null,
}

//Get all quests
export const getAllQuests = createAsyncThunk(
  'quests/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await questsService.getAllQuests(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Create new quest
export const createNewPost = createAsyncThunk(
  'quests/create-quest',
  async (data: CreateQuestFormValues, thunkAPI) => {
    try {
      return await questsService.createNewQuest(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Upload video
export const uploadVideoForQuest = createAsyncThunk(
  'quest/upload-video',
  async (data: any, thunkAPI) => {
    try {
      return await questsService.uploadVideo(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Get single arena
export const getSingleArena = createAsyncThunk(
  'quest/get-single-arena',
  async (id: string, thunkAPI) => {
    try {
      return await questsService.getSingleArena(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Delete single arena
export const deleteSingleArena = createAsyncThunk(
  'quest/delete-single-arena',
  async (id: number, thunkAPI) => {
    try {
      return await questsService.deleteSingleArena(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Update single arena
export const updateSingleArena = createAsyncThunk(
  'quest/update',
  async (data: any, thunkAPI) => {
    try {
      return await questsService.updateQuest(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const questSlice = createSlice({
  name: 'quests',
  initialState: initialQuestState,
  reducers: {
    resetQuestState(state) {
      state.uploadVideoSuccess = false
      state.isSuccess = false
      state.isError = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllQuests.pending, state => {
        state.isLoading = true
      })
      .addCase(getAllQuests.fulfilled, (state, action) => {
        state.isLoading = false
        state.quests = action.payload.data
      })
      .addCase(getAllQuests.rejected, state => {
        state.quests = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(createNewPost.pending, state => {
        state.isLoading = true
      })
      .addCase(createNewPost.fulfilled, (state, action) => {
        state.isLoading = false
        state.url = action.payload.data.data.url
        state.id = action.payload.id
      })
      .addCase(createNewPost.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.url = null
        state.id = null;
      })

      .addCase(uploadVideoForQuest.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadVideoForQuest.fulfilled, (state, action) => {
        state.isLoading = false
        state.uploadVideoSuccess = true
      })
      .addCase(uploadVideoForQuest.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(getSingleArena.pending, state => {
        state.isLoading = true
      })
      .addCase(getSingleArena.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.singleQuest = action.payload
      })
      .addCase(getSingleArena.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.singleQuest = null
      })

      .addCase(deleteSingleArena.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteSingleArena.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.quests = state.quests && {
          ...state.quests,
          items: state.quests.items.filter(x => x.id !== +action.payload),
        }
      })
      .addCase(deleteSingleArena.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(updateSingleArena.pending, state => {
        state.isLoading = true
      })
      .addCase(updateSingleArena.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updateSingleArena.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
  },
})

export const { resetQuestState } = questSlice.actions
export default questSlice.reducer
