import { Categories, ChildCategory } from "../../models/categories.models";
import api from "../interceptors/api";

//Get all categories
const getAllCategories = async () => {
  
    const response = await api.get(`/categories`);
    return response.data as Categories;
  };

  //Delete category by id
const deleteCategoryById = async (id: number) => {
  
  const response = await api.delete(`/categories/delete/${id}`);
  return id;
};


  //Add new category
  const addNewCategory = async (data: any) => {
  
    const response = await api.post(`/categories/`, data);
    return response.data.data as ChildCategory;
  };


    //Editcategory
    const editCategory = async (data: any) => {
      const response = await api.put(`/categories/update/${data.id}`, data);
      return data as ChildCategory;
    };

  const categoryService = {
  getAllCategories,
  deleteCategoryById,
  addNewCategory,
  editCategory
  };
  
  export default categoryService;