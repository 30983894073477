import React from "react";

interface Props {
  id: string;
  previousId: string | null;
  nextId: string | null;
  value: string;
  onValueChange: (id:string, e: any) => void;
  handleSubmit: (e: any) => void;
}

const OTPInput = ({
  id,
  previousId,
  nextId,
  value,
  onValueChange,
  handleSubmit,
}: Props) => {

    const handleKeyUp = (e: any) => {

        if ((e.keyCode === 8 || e.keyCode === 37) && previousId) {

            const prev = document.getElementById(previousId) as HTMLInputElement;
      if (prev) {
        prev.select();
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) || //check if key is numeric keys 0 to 9
      (e.keyCode >= 65 && e.keyCode <= 90) || //check if key is alphabetical keys A to Z
      (e.keyCode >= 96 && e.keyCode <= 105) || //check if key is numeric keypad keys 0 to 9
      e.keyCode === 39 //check if key is right arrow key
    ) {
      const next = nextId && document.getElementById(nextId) as HTMLInputElement;
      if (next) {
        next.select();
      } else {

          handleSubmit(e);
        
      }
    }
  };
  return (
    <input
    className="m-2 text-center form-control rounded"
      id={id}
      name={id}
      type="text"
      value={value}
      maxLength={1}
      onChange={(e) => onValueChange(id, e.target.value)}
      onKeyUp={handleKeyUp}
    />
  );
};

export default OTPInput;
