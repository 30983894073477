import React, { useEffect, useState } from 'react'
import { SingleUser } from '../../models/users.model'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useFormik } from 'formik'
import { createNewUser, updateSingleUser } from '../../store/users/user_slice'
import BreadCrumbs from '../shared/BreadCrumbs'
import LoadSpinner from '../shared/LoadSpinner'
import { toast } from 'react-toastify'
import UploadImageForm from './UploadImageForm'
import './user.scss'
import { AiFillDelete } from 'react-icons/ai'
import countryCodes from '../../countryCodes.json'

interface Props {
  is_edit: boolean
  data?: SingleUser
  id?: string
  page?: number
}

const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string(),
    country_code: Yup.string(),
    phone: Yup.number(),
    role_id: Yup.number().required(),
  })
  .test(
    'all-three-required',
    'All three fields are required when they are empty',
    function (values) {
      const { email, country_code, phone } = values
      if (!email && !country_code && !phone) {
        return this.createError({
          path: 'email',
          message: 'Email is required',
        })
      } else if (email && !country_code && !phone) {
        return true
      } else if (!email && country_code && phone) {
        return true
      } else if (!email && !country_code && phone) {
        return this.createError({
          path: 'country_code',
          message: 'Country code is required',
        })
      } else if (!email && country_code && !phone) {
        return this.createError({
          path: 'phone',
          message: 'Phone is required',
        })
      }
      return true
    }
  )

function UserForm({ is_edit, data, id, page }: Props) {
  const breadCrumbs = [
    { name: `Users list`, path: `/users?page=${page ? page : 1}` },
  ]
  const [showImage, setShowImage] = useState(false)
  const [image, setImage] = useState<File | null>(null)
  const dispatch = useAppDispatch()
  const { isLoading, isSuccess } = useAppSelector(state => state.users)

  const formik = useFormik({
    initialValues: {
      name: data && data.name ? data.name : '',
      profile_image: data && data.profile_image ? data.profile_image : '',
      email:
        data && data.emails && data.emails.length > 0
          ? data.emails[0].email
          : '',
      phone:
        data && data.phones && data.phones.length > 0
          ? data.phones[0].phone
          : '',
      country_code:
        data && data.phones && data.phones.length > 0
          ? data.phones[0].country_code
          : '',
      role_id: data && data.role_id && data.role_id.id === 1 ? 1 : 2,
    },
    onSubmit: (values: any) => {
      const formData = new FormData()
      if (image && id) {
        formData.append('profile_image', image)
      }
      if (is_edit && id) {
        values.id = id
        values.role_id = parseInt(values.role_id)
        values.phone = String(values.phone)
        dispatch(updateSingleUser({ values, formData }))
      } else {
        if (image) {
          formData.append('profile_image', image)
        }
        if (values.email === '') {
          formData.delete('email')
        }
        values.phone = String(values.phone)
        const userData = { values, formData }
        dispatch(createNewUser(userData))
      }
    },
    validationSchema,
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success('User action succesufully')
      setTimeout(() => {
        window.location.replace(`/users?page=${page ? page : 1}`)
      }, 2000)
    }
  }, [dispatch, isSuccess])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">
          {is_edit ? `Edit user #${id}` : 'Create new user'}
        </h1>

        <BreadCrumbs
          breadcrumbs={breadCrumbs}
          activeName={is_edit ? `Edit user #${id}` : 'New user'}
        />
      </div>

      <form onSubmit={formik.handleSubmit} className="custom-form row">
        <div className="col-12 mb-4">
          <div className="d-flex justify-content-center">
            {formik.values.profile_image && !showImage ? (
              <div className="selected-image">
                <img
                  className="addedImage"
                  src={`${
                    formik.values.profile_image
                  }?timestamp=${new Date().getTime()}`}
                  alt="Profile image"
                />
                <div
                  className="image-overlay"
                  onClick={() => {
                    setShowImage(true)
                  }}
                >
                  <button className="uploadText delImgIcon">
                    <AiFillDelete color="red" size={25} />
                  </button>
                </div>
              </div>
            ) : (
              <UploadImageForm image={image} setImage={setImage} />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="name" className="custom-label">
              Name
            </label>
            <input
              name="name"
              id="name"
              className="custom-input"
              placeholder="Enter a name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="error">{formik.errors.name.toString()}</div>
            )}
          </div>
          {is_edit && formik.values.email ? (
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="form-group">
                <label htmlFor="email" className="custom-label">
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  className="custom-input"
                  placeholder="Enter an email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email.toString()}</div>
                )}
              </div>
            </div>
          ) : null}
          {!is_edit ? (
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="form-group">
                <label htmlFor="email" className="custom-label">
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  className="custom-input"
                  placeholder="Enter an email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email.toString()}</div>
                )}
              </div>
            </div>
          ) : null}
          {is_edit && formik.values.country_code && formik.values.phone ? (
            <div className="d-flex align-items-center mb-2 w-100">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="country_code" className="custom-label">
                    Country code
                  </label>
                  <select
                    name="country_code"
                    id="country_code"
                    className="custom-input"
                    onChange={formik.handleChange}
                    defaultValue={formik.values.country_code}
                  >
                    {countryCodes.countries.map((country, index) => (
                      <option
                        style={{ backgroundColor: 'black' }}
                        key={index}
                        value={country.dial_code}
                      >
                        {country.name} ({country.dial_code})
                      </option>
                    ))}
                  </select>
                  {formik.touched.country_code &&
                    formik.errors.country_code && (
                      <div className="customError">
                        {formik.errors.country_code.toString()}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7">
                <div className="form-group">
                  <label htmlFor="phone" className="custom-label">
                    Phone
                  </label>
                  <input
                    name="phone"
                    type="number"
                    id="phone"
                    className="custom-input"
                    placeholder="Enter a phone number"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="customError">
                      {formik.errors.phone.toString()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {!is_edit ? (
            <div className="d-flex align-items-center mb-2 w-100">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="country_code" className="custom-label">
                    Country code
                  </label>
                  <select
                    name="country_code"
                    id="country_code"
                    className="custom-input"
                    onChange={formik.handleChange}
                    defaultValue={formik.values.country_code}
                  >
                    {countryCodes.countries.map((country, index) => (
                      <option
                        style={{ backgroundColor: 'black' }}
                        key={index}
                        value={country.dial_code}
                      >
                        {country.name} ({country.dial_code})
                      </option>
                    ))}
                  </select>
                  {formik.touched.country_code &&
                    formik.errors.country_code && (
                      <div className="customError">
                        {formik.errors.country_code.toString()}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7">
                <div className="form-group">
                  <label htmlFor="phone" className="custom-label">
                    Phone
                  </label>
                  <input
                    name="phone"
                    type="number"
                    id="phone"
                    className="custom-input"
                    placeholder="Enter a phone number"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="customError">
                      {formik.errors.phone.toString()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-12 mb-4">
            <div className="form-group">
              <label htmlFor="role" className="custom-label">
                Role
              </label>
              <select
                name="role_id"
                id="role_id"
                className="custom-input"
                value={formik.values.role_id}
                onChange={formik.handleChange}
              >
                <option style={{ backgroundColor: 'black' }} value={1}>
                  User
                </option>

                <option style={{ backgroundColor: 'black' }} value={2}>
                  Admin
                </option>
              </select>
              {formik.touched.role_id && formik.errors.role_id && (
                <div className="error">{formik.errors.role_id.toString()}</div>
              )}
            </div>
          </div>
          <div className="form-group">
            <button disabled={isLoading} type="submit" className="save-button">
              {isLoading ? (
                <LoadSpinner
                  width={15}
                  height={15}
                  loadMessage={
                    is_edit ? `Editing user #${id}... ` : 'Creating user... '
                  }
                />
              ) : (
                <>{is_edit ? `Edit user #${id}` : 'Create user'}</>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default UserForm
