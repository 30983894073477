import { ReportTypes } from "../../../models/reports.models";
import api from "../../interceptors/api";

// Get all reports types
const getAllReportsTypes = async () => {
  
    const response = await api.get(`/reports/types`);
    return response.data as ReportTypes;
  };


  const reportTypesService = {
    getAllReportsTypes
    };
    
    export default reportTypesService;