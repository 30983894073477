import React from "react";
import { useAppDispatch } from "../../store/store";
import { Meta } from "../../models/shared.models";
import { createSearchParams, useNavigate } from "react-router-dom";

interface Props {
  meta: Meta;
  switchFunction: (data: any) => any;
  data: any
  setPage: (page: number) => any
  path: string
}

function Pagination({ meta, switchFunction, data, setPage, path }: Props) {
  const dispatch = useAppDispatch();
  const naviagte = useNavigate()

  const renderNumbers = () => {
    const listItems = [];

    for (let i = 1; i <= meta.totalPages; i++) {
      listItems.push(
        <li onClick={() => onPageClicked(i)} key={i} className={`page-item ${i === meta.currentPage ? 'disabled' : ''}`}>
          <span className="page-link" >
            {i}
          </span>
        </li>
      );
    }

    return listItems;
  };

  const onPageClicked = (page: number) => {
    data.page = page
    dispatch(switchFunction(data));
    setPage(page);
    const searchParams = createSearchParams(data).toString()
    naviagte({
      // pathname: path,
      search: searchParams
    })
   
  };

  return (
    <nav aria-label="Pagination">
      <ul className="pagination justify-content-end">
        <li
          onClick={() => onPageClicked(1)}
          className={`page-item ${meta.currentPage === 1 ? "disabled" : ""}`}
        >
          <span className="page-link"  aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>

        {renderNumbers()}
        <li
          onClick={() => onPageClicked(meta.totalPages)}
          className={`page-item ${
            meta.currentPage === meta.totalPages ? "disabled" : ""
          }`}
        >
          <span className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
