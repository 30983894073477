import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/shared/BreadCrumbs'
import QuestVideosPage from './Tabs/QuestVideos'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  QuestDetailsBradcrumbs,
  QuestDetailsTitles,
} from '../../data/quest_details_data'
import JoinQuest from './Tabs/JoinQuest'
import QuestDuels from './Tabs/QuestDuels'

const breadCrumbs = [{ name: `Quests List`, path: `/quests-list` }]

function QuestDetailsPage() {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [active, setActive] = useState(parseInt(searchParams.get("active") || "0"))
let data = {
  active: active.toString()
}



  const changeTab = (tabNumber: number) => {
     data = {
      active: tabNumber.toString()
    }
    setSearchParams(data)
    setActive(tabNumber)
  }


  useEffect(() => {
    setSearchParams(data)
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center">
        <h1 className="white">
          {QuestDetailsTitles[active]} #{id}
        </h1>

        <BreadCrumbs
          breadcrumbs={breadCrumbs}
          activeName={`${QuestDetailsBradcrumbs[active]} #${id}`}
        />
      </div>

      <div className="pb-5 container">
        <div className="w-100 d-flex align-items-center">
          <div
          onClick={(e) => changeTab(0)}
            className={`me-3 cursor-pointer ${
              active === 0 ? 'white border-active-tabs' : 'text-white-50'
            }`}
          >
            Quest videos
          </div>

          <div
          onClick={(e) => changeTab(1)}
            className={`me-3 cursor-pointer ${
              active === 1 ? 'white border-active-tabs' : 'text-white-50'
            }`}
          >
            See duels
          </div>

          <div
          onClick={(e) => changeTab(2)}
            className={`me-3 cursor-pointer ${
              active === 2 ? 'white border-active-tabs' : 'text-white-50'
            }`}
          >
            Join quest
          </div>
        </div>
      </div>

      {active === 0 && <QuestVideosPage />}

      {active === 1 && <QuestDuels />}


      {active === 2 && <JoinQuest />}
    </>
  )
}

export default QuestDetailsPage
