import axios from 'axios'
import api from '../../interceptors/api'
import { request } from 'http'

export const getVideosForArena = async (data: any) => {
  const response = await api.post('/challenges/arena/index', data)

  return response.data
}

export const getSingleVideo = async (id: any) => {
  const response = await api.get(`/posts/db/${id}`)

  return response.data.data
}

export const deleteVideoFromQuest = async (id: number) => {
  const response = await api.delete(`/posts/${id}`)

  return id
}

export const updateVideo = async (data: any) => {
  const response = await api.put(`/posts`, data)

  return response.data
}

export const createPostToJoinQuest = async (data: any) => {
  const response = await api.post('/posts', data)

  if (response.data.data.post.id) {
    const asignResponse = await api.post('/challenges/arena', {
      post_id: response.data.data.post.id,
      challenge_parent_type_id: +data.arena_id,
    })
  }

  return response.data
}

const uploadVideo = async (params: any) => {
  let data = params.get('video')
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: params.get('url'),
    headers: {
      'Content-Type': 'video/mp4',
    },
    data: data,
  }
  const response = await axios.request(config)

  return response.data
}

const questVideosService = {
  getVideosForArena,
  deleteVideoFromQuest,
  updateVideo,
  getSingleVideo,
  createPostToJoinQuest,
  uploadVideo,
}

export default questVideosService
