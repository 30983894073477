import axios from 'axios'
import { storeOrderList } from '../../models/storeOrder.models'
import api from '../interceptors/api'

//Get all store orders
const getAllStoreOrder = async (data: any = 1) => {
  const response = await api.post(`/orders/index`, data)
  return response.data as storeOrderList
}

const deleteSingleStoreOrder = async (id: number) => {
  const response = await api.delete(`/orders/${id}`)
  return id
}

const updateSingleStoreOrder = async (data: any) => {
  const id = data.values.id
  const response = await api.patch(`/orders/${id}`, data.values)
  return response.data
}

const storeOrderService = {
  getAllStoreOrder,
  deleteSingleStoreOrder,
  updateSingleStoreOrder,
}

export default storeOrderService
