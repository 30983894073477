import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  deleteSingleArena,
  getAllQuests,
  resetQuestState,
} from '../../store/quests/quest_slice'
import { Item } from '../../models/quest.model'
import QuestTableActions from '../../components/quest/QuestTableActions'
import CustomTable from '../../components/shared/CustomTable'
import { toast } from 'react-toastify'
import '../../components/quest/quest.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiReset } from 'react-icons/bi'

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Reset filters
  </Tooltip>
)
function QuestListPage() {
  const { quests, isLoading } = useAppSelector(state => state.quests)
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [startDate, setStartDate] = useState(searchParams.get('dateFrom') || '')
  const [endDate, setEndDate] = useState(searchParams.get('dateTo') || '')
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const [data, setData] = useState<any>(null)
  const [name, setName] = useState(searchParams.get('search') || '')
  const [status, setStatus] = useState<any>(
    parseInt(searchParams.get('status') || '')
  )

  const navigate = useNavigate()

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value
    if (newEndDate >= startDate) {
      setEndDate(newEndDate)
    } else {
      toast.error('End date cannot be earlier than the start date.')
    }
  }
  const resetFilters = () => {
    setPage(1)
    setName('')
    setStartDate('')
    setEndDate('')
    setStatus('')
    setData(null)
    navigate('/quests-list?page=1')
  }

  let dataa: any = {
    limit: 20,
    page: status || startDate || endDate || name ? 1 : page,
    status: +status,
    dateFrom: startDate,
    dateTo: endDate,
    search: name,
  }
  const serachByName = (name: string) => {
    setName(name)

    if (name.length > 0 || name === '') {
      dataa = {
        ...dataa,
        search: name,
      }
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllQuests(dataa))
      dispatch(resetQuestState())
    }
  }
  useEffect(() => {
    setData(dataa)
    setSearchParams(dataa)
    dispatch(getAllQuests(dataa))
    dispatch(resetQuestState())
  }, [status, startDate, endDate])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center">
        <h1 className="white">Quest List</h1>
        <Link to="/new-quest" className="add-new-button">
          Add new quest
        </Link>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-8 searchStatus">
          <div className="col-4 searchBar">
            <input
              name="name"
              id="name"
              className="custom-input"
              placeholder="Search by ID, Name or Creator"
              type="text"
              value={name}
              onChange={e => serachByName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="" className="custom-label">
              Status
            </label>
            <select
              value={status}
              onChange={e => setStatus(e.target.value)}
              name=""
              id=""
              className="custom-input"
            >
              <option style={{ backgroundColor: 'black' }} value={0}>
                All
              </option>
              <option style={{ backgroundColor: 'black' }} value={1}>
                Published
              </option>
              <option style={{ backgroundColor: 'black' }} value={2}>
                Pending
              </option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label htmlFor="" className="custom-label">
              Start date
            </label>
            <input
              name=""
              required
              id=""
              className="custom-input"
              placeholder="Pick start date"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <div className="dash"> - </div>
          <div className="d-flex">
            <div>
              {' '}
              <label htmlFor="" className="custom-label">
                End date
              </label>
              <div className="d-flex align-items-center">
                <input
                  name=""
                  required
                  id=""
                  className="custom-input"
                  placeholder="Pick end date"
                  type="date"
                  value={endDate}
                  onChange={e => onEndDateChange(e)}
                />
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button onClick={resetFilters} className="delete-button">
                    <BiReset
                      size={30}
                      color="white"
                      className="reset-filters"
                    />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomTable
        path="quests-list"
        setPage={setPage}
        isLoading={isLoading}
        meta={quests?.meta}
        switchFunction={getAllQuests}
        header={<HeaderTable />}
        searchData={data}
        data={<QuestData page={page} quests={quests} isLoading={isLoading} />}
      />
    </>
  )
}

export default QuestListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Thumbnail</th>
      <th>Name</th>
      <th>Creator</th>
      <th>Status</th>
      <th>Number of contestants</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const QuestData = (props: any) => {
  return (
    <>
      {props.quests && props.quests.items.length > 0 && !props.isLoading ? (
        props.quests.items.map((item: Item, index: number) => (
          <tr key={index}>
            <td className="align-middle">
              <strong>{item.id}</strong>
            </td>
            <td className="align-middle">
              <img
                style={{ width: '50px', height: '70px', objectFit: 'cover' }}
                src={item.post_id.thumbnail_url}
              />
            </td>
            <td className="align-middle">{item.name}</td>
            <td className="align-middle">{item.creator_name}</td>
            <td className="align-middle">{item.status.name}</td>
            <td className="align-middle">{item.total_users}</td>
            <td className="align-middle">
              <QuestTableActions
                page={props.page}
                item={item}
                id={item.id}
                deleteFunction={deleteSingleArena}
              />
            </td>
          </tr>
        ))
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={7} className="text-center text-white-50">
                <h6 className="mb-0">No matching quests found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
