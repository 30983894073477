import { Meta, Tag, User } from "./shared.models"

export interface Quests {
    data: Data
    status: boolean
    error: any
  }

export interface SingleQuets{
  data: Item
  status: boolean
  error: any
}
  
  export interface Data {
    items: Item[]
    meta: Meta
  }
  
  export interface Item {
    id: number
    created_at: string
    updated_at: string
    name: string
    creator_name: string
    expires_at: string
    description: string
    limit: any
    creator_profile_image: any
    pool_amount: string
    prompt: string;
    post_id: PostId
    status: Status
    users: User[]
    joined: boolean
    total_users: number
    category: any
    scheduled_at: string

  }
  
  export interface PostId {
    id: number
    post_url: string
    description: string
    title: any
    is_deleted: boolean
    created_at: string
    updated_at: string
    is_downloadable: boolean
    publish_status: string
    thumbnail_url: string
    lat: any
    long: any
    comments_enabled: boolean
    monetization_enabled: boolean
    challenge_mode_enabled: boolean
    raw_url: string
    max_bid_amount: any
    min_bid_amount: any
    processing_message_id: string
    output_message_id: string
    content_id: any
    user: User
    tags: Tag[]
  }
  

  
  export interface Status {
    id: number
    name: string
  }


  export type CreateQuestFormValues = {
    description: string;
    tags: string[];
    title: string;
    creator_name?: string;
    content_type: Number;
    publish_status: string
    prompt: string
    category: number
    expires_at: any
    scheduled_at: any
  };

  export const formatTags = (tags: Tag[]) => {
    let tagsName:string[] = []

    tags.forEach((tag: Tag) => {
      tagsName.push(tag.name)
    })

    return tagsName;
  }
  