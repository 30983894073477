// Get all reports

import { PostId } from "../../models/quest.model";
import { PostReport, ReportList, SingleReports } from "../../models/reports.models";
import api from "../interceptors/api";

const getAllReports = async (data: any) => {
  const response = await api.post(`/reports/index`, data);

  return response.data.data as ReportList;
};

const getSingleReport = async (id: any) => {
  const data = {
    arena_id: +id,
    limit: 5,
    page: 1
  }
  const response = await api.post(`/reports/index`, data);

  return response.data.data.items[0] as SingleReports;
};

const getPostForReport = async (id:string) => {
  const response = await api.get(`/posts/db/${id}`)

  return response.data.data as PostReport;
}


const changeStatusOfReport = async (data: any) => {
  const dataa = {
    status: data.status
  }
    const response = await api.patch(`/reports/${data.id}`, dataa);

    return response.data.data as SingleReports;
}

const reportsService = {
  getAllReports,
  getPostForReport,
  changeStatusOfReport,
  getSingleReport
};

export default reportsService;
