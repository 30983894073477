import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  getAllCategories,
  resetcategoryState,
} from '../../store/categories/categories_slice'
import { ChildCategory, ParentCategory } from '../../models/categories.models'
import CustomTable from '../../components/shared/CustomTable'
import CategoryTableAction from '../../components/category/CategoryTableAction'
import ModalCategory from '../../components/category/ModalCategory'
import { toast } from 'react-toastify'

function CategoryListPage() {
  const { categories, isLoading, successCRUD } = useAppSelector(
    state => state.categories
  )

  const dispatch = useAppDispatch()
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (successCRUD) {
      toast.success('Success')
      dispatch(getAllCategories())
      dispatch(resetcategoryState())
    }
  }, [successCRUD])

  useEffect(() => {
    dispatch(getAllCategories())
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">Categories List</h1>
        <button
          onClick={() => setShowModalCreate(true)}
          className="add-new-button"
        >
          Add new category
        </button>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-4 searchBar">
          <input
            name=""
            id=""
            className="custom-input"
            placeholder="Search by Unique name"
            type="text"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <CustomTable
        isLoading={isLoading}
        header={<HeaderTable />}
        data={
          <CategoriesData
            categories={categories}
            isLoading={isLoading}
            searchQuery={searchQuery}
          />
        }
      />

      {showModalCreate && categories && (
        <ModalCategory
          categories={categories}
          is_edit={false}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </>
  )
}

export default CategoryListPage

const HeaderTable = () => {
  return (
    <>
      <th>#</th>
      <th>Unique name</th>
      <th>Description</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const CategoriesData = (props: any) => {
  const { categories, isLoading, searchQuery } = props

  const filteredCategories = (categories?.data || [])
    .map((parentCategory: ParentCategory) => {
      if (searchQuery.trim() === '') {
        return {
          ...parentCategory,
          children: parentCategory.children,
        }
      }
      const matchingChildCategory = parentCategory.children.filter(
        (childCategory: ChildCategory) => {
          if (
            childCategory.name.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return childCategory
          }
        }
      )
      if (matchingChildCategory.length > 0) {
        return {
          ...parentCategory,
          children: matchingChildCategory,
        }
      }
      return null
    })
    .filter(Boolean)
  if (filteredCategories.length === 0 && searchQuery.trim() !== '') {
    return (
      <tr>
        <td colSpan={4} className="text-center text-white-50">
          <h6 className="mb-0">No matching categories found</h6>
        </td>
      </tr>
    )
  }

  return (
    <>
      {filteredCategories &&
        !isLoading &&
        filteredCategories.map((category: ParentCategory) => (
          <React.Fragment key={category.id}>
            <tr>
              <td className="text-center" colSpan={4}>
                <strong>{category.name}</strong>
              </td>
            </tr>
            {category.children.map(
              (childCategory: ChildCategory, childIndex: number) => (
                <tr key={childCategory.id}>
                  <td className="align-middle">
                    <strong>{childIndex + 1}</strong>
                  </td>
                  <td className="align-middle">{childCategory.name}</td>
                  <td className="align-middle">
                    {childCategory.description
                      ? childCategory.description
                      : '/'}
                  </td>
                  <td className="align-middle">
                    <CategoryTableAction
                      categories={categories}
                      id={childCategory.id}
                      category={childCategory}
                    />
                  </td>
                </tr>
              )
            )}
          </React.Fragment>
        ))}
    </>
  )
}
