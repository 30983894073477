import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store';
import LoadSpinner from '../../shared/LoadSpinner';
import { uploadVideoToJoinQuest } from '../../../store/quests/quest-video/quest-video_slice';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function QuestVideoUploadForm() {
    const { url, isLoading, uploadVideoSuccess, id: postId  } = useAppSelector(
        (state) => state.questVideo
      );
      const dispatch = useAppDispatch();
      const [video, setVideo] = useState<any>(null);
      const [selectedFileName, setSelectedFileName] = useState(null);
      const {id} = useParams()

      const uploadVideo = (e: any) => {
        e.preventDefault();
        let data = new FormData();
        data.append("video", video);
        const decodedurl = url && JSON.parse(url);
        if (decodedurl && decodedurl.uploadURL) {
          data.append("url", decodedurl.uploadURL);
          if(id && postId){
            data.append("arena_id", id)
            data.append('post_id', postId)
          }
         
          dispatch(uploadVideoToJoinQuest(data));
        }
      };
    
      const onVideoChange = (e: any) => {
        setVideo(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
          setSelectedFileName(file.name);
        } else {
          setSelectedFileName(null);
        }
      };


      useEffect(() => {
        if (uploadVideoSuccess && id) {
          toast.success('Video uploaded succesufully')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      }, [dispatch, uploadVideoSuccess, id])
  return (
    <form style={{maxWidth:500}} onSubmit={uploadVideo}>
    <div className="form-group">
      <label className="custom-file-label">
        <input
          type="file"
          id="fileInput"
          accept=".mp4"
          onChange={(e) => onVideoChange(e)}
        />
        Choose a video
      </label>
      <div className="selected-file-name">
        {selectedFileName && `Selected File: ${selectedFileName}`}
      </div>

      <button
        disabled={isLoading || !video || uploadVideoSuccess}
        type="submit"
        className="custom-button"
      >
        {isLoading ? (
          <LoadSpinner
            width={15}
            height={15}
            loadMessage="Uploading video... "
          />
        ) : (
          "Upload Video"
        )}
      </button>

    
    </div>
  </form>
  )
}

export default QuestVideoUploadForm