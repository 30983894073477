import axios from "axios";
import { bearerToken } from "../login/login_slice";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3001"; // Include the port number

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  (config) => {
    if (bearerToken) {
      if(config.url && !config.url.includes('/challenges/arena/index'))
      config.headers.Authorization = `Bearer ${bearerToken}`;
    }else{
      config.headers.Authorization = `Bearer `;
    }
    return config;
  },
  (error) => {
    console.log("error request", error);

    if (error.error) {
      toast.error(error.error);
    } else {
      toast.error("Something went wrong!");
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error response", error);
    if (error.error) {
      toast.error(error.error);
    } else {
      toast.error("Something went wrong!");
    }

    return Promise.reject(error);
  }
);

export default api;
