import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/shared/BreadCrumbs'
import ReactPlayer from 'react-player'
import DeleteModal from '../../components/shared/DeleteModal'
import {
  deleteVideoFromQuest,
  resetQuestVideoState,
} from '../../store/quests/quest-video/quest-video_slice'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
  changeStatusOfReport,
  getPostForReport,
  getSinglereport,
} from '../../store/reports/reports_slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { toast } from 'react-toastify'
import LoadSpinner from '../../components/shared/LoadSpinner'

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Delete video if it's violate platform's guidelines, integrity or user
    safety.
  </Tooltip>
)

function ReportDetailsPage() {
  const { post, successCRUD, singleReport, isLoading } = useAppSelector(
    state => state.reports
  )
  const { isCDSuccess } = useAppSelector(state => state.questVideo)
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false)
  const location = useLocation()
  const { reportItem, page } = location && location.state || {}
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [item, setItem] = useState(reportItem ? reportItem : singleReport)

  const changeReportStatus = (status: string) => {
    const data = {
      status,
      id,
    }

    dispatch(changeStatusOfReport(data))
  }

  useEffect(() => {
    if (item) {
      dispatch(getPostForReport(item.type_id))
    }

    if (!item && id) {
      dispatch(getSinglereport(id))
    }

    if (singleReport) {
      setItem(singleReport)
    }
  }, [singleReport])

  useEffect(() => {
    if (successCRUD) {
      toast.success('Success')
      navigate(`/reports?page=${page ? page : 1}`)
    }

    if (isCDSuccess) {
      toast.success('Video deleted')
      dispatch(resetQuestVideoState())
    }
  }, [dispatch, successCRUD, isCDSuccess])

  const breadCrumbs = [{ name: `Reports list`, path: `/reports?page=${page ? page : 1}` }]

  if (isLoading && !singleReport && !item) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />;
  }
  return (
    <>
      {item && post && (
        <>
          <div className="d-flex justify-content-between mb-5 mt-3 align-items-center flex-wrap-reverse">
            <h1 className="white">Report #{id}</h1>

            <BreadCrumbs
              activeName={`Report #${id}`}
              breadcrumbs={breadCrumbs}
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-md-6 mb-4">
                <ReactPlayer
                  url={post.post_url}
                  width="100%"
                  height="70%"
                  controls={true}
                  loop={true}
                />
              </div>

              <div className="col-xl-7 col-md-6 mb-4">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="report-title mb-4">
                      {item.report_type_id.name} report for post #{post.id}
                    </h1>
                  </div>

                  <div className="col-12 mb-4">
                    <label className="custom-label-reports">Description</label>
                    <p className="custom-text-reprorts">
                      {item.description ? item.description : '/'}
                    </p>
                  </div>

                  <div className="col-12 mb-4 d-flex align-items-center">
                    <label className="custom-label-reports">User:</label>
                    <p className="custom-text-reprorts-align">
                      {post.user_id && post.user_id.name
                        ? post.user_id.name || post.user_id.username
                        : '/'}
                    </p>
                  </div>

                  <div className="col-12 mb-4 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <label className="custom-label-reports">Post:</label>
                      <p className="custom-text-reprorts-align">
                        {post.title} - #{post.id}
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          setShowModal(true)
                        }}
                        className="view-item"
                        style={{ color: '#D22B2B', marginRight: 10 }}
                      >
                        Delete video #{post.id}
                      </span>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <button className="delete-button">
                          <AiOutlineInfoCircle size={20} color="white" />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="col-12 mb-4 d-flex justify-content-end align-items-center reports-buttons">
                    {item.status === 'in_process' ? (
                      <>
                        <button
                          onClick={e => {
                            changeReportStatus('completed')
                          }}
                          className="btn btn-info"
                        >
                          Approve
                        </button>

                        <button
                          onClick={e => {
                            changeReportStatus('declined')
                          }}
                          className="btn btn-danger"
                        >
                          Decline
                        </button>
                      </>
                    ) : (
                      <>
                        <label className="custom-label-reports">Status:</label>
                        <p className="custom-text-reprorts-align">
                          {item.status}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <DeleteModal
              id={item.id}
              deleteFunction={deleteVideoFromQuest}
              show={showModal}
              onHide={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportDetailsPage
