import React, { useState, useEffect } from 'react'
import CustomTable from '../../components/shared/CustomTable'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiReset } from 'react-icons/bi'
import './store.scss'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getAllStoreOrder,
  resetStoreOrderState,
} from '../../store/storeOrders/storeOrder_slice'
import { SingleOrder } from '../../models/storeOrder.models'
import { RxAvatar } from 'react-icons/rx'
import moment from 'moment'
import StoreTableOrderAction from '../../components/store/StoreTableOrderAction'
import ModalStoreOrder from '../../components/store/ModalStoreOrder'

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Reset filters
  </Tooltip>
)
function StoreOrdersListPage() {
  const { storeOrder, isLoading, successCRUD } = useAppSelector(
    state => state.storeOrder
  )
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const [data, setData] = useState<any>(null)
  const [startDate, setStartDate] = useState(searchParams.get('dateFrom') || '')
  const [endDate, setEndDate] = useState(searchParams.get('dateTo') || '')
  const [name, setName] = useState(searchParams.get('search') || '')
  const initialStatus =
    searchParams.get('status') !== null ? searchParams.get('status') : ''
  const [status, setStatus] = useState(initialStatus)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value
    if (newEndDate >= startDate) {
      setEndDate(newEndDate)
    } else {
      toast.error('End date cannot be earlier than the start date.')
    }
  }

  const resetFilters = () => {
    setPage(1)
    setName('')
    setStartDate('')
    setEndDate('')
    setStatus('')
    setData(null)
    navigate('/store-orders?page=1')
  }
  let dataa: any = {
    limit: 20,
    page: startDate || endDate || name ? 1 : page,
    dateFrom: startDate,
    dateTo: endDate,
    status: status,
    search: name,
  }
  const serachByName = (name: string) => {
    setName(name)

    if (name.length > 3 || name === '') {
      dataa = {
        ...dataa,
        search: name,
      }
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllStoreOrder(dataa))
      dispatch(resetStoreOrderState())
    }
  }
  useEffect(() => {
    if (successCRUD) {
      toast.success('Success')
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllStoreOrder(dataa))
      dispatch(resetStoreOrderState())
    }
  }, [successCRUD])

  useEffect(() => {
    setData(dataa)
    setSearchParams(dataa)
    dispatch(getAllStoreOrder(dataa))
    dispatch(resetStoreOrderState())
  }, [status, startDate, endDate])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">Store Orders List</h1>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-6 searchStatus">
          <div className="col-6 searchBar">
            <input
              name="name"
              id="name"
              className="custom-input"
              placeholder="Search by Username or Product name"
              type="text"
              value={name}
              onChange={e => serachByName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="" className="custom-label">
              Status
            </label>
            <select
              name=""
              id=""
              className="custom-input"
              value={status === null ? '' : status}
              onChange={e => setStatus(e.target.value)}
            >
              <option style={{ backgroundColor: 'black' }} value={''}>
                All
              </option>
              <option style={{ backgroundColor: 'black' }} value={'completed'}>
                Completed
              </option>
              <option style={{ backgroundColor: 'black' }} value={'pending'}>
                Pending
              </option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label htmlFor="" className="custom-label">
              Start date
            </label>
            <input
              name=""
              required
              id=""
              className="custom-input"
              placeholder="Pick start date"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <div className="dash"> - </div>
          <div className="d-flex">
            <div>
              {' '}
              <label htmlFor="" className="custom-label">
                End date
              </label>
              <div className="d-flex align-items-center">
                <input
                  name=""
                  required
                  id=""
                  className="custom-input"
                  placeholder="Pick end date"
                  type="date"
                  value={endDate}
                  onChange={e => onEndDateChange(e)}
                />

                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button onClick={resetFilters} className="delete-button">
                    <BiReset
                      size={30}
                      color="white"
                      className="reset-filters"
                    />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomTable
        path="store-orders"
        setPage={setPage}
        isLoading={isLoading}
        meta={storeOrder?.meta}
        switchFunction={getAllStoreOrder}
        header={<HeaderTable />}
        searchData={data}
        data={
          <StoreOrdersData
            storeOrder={storeOrder}
            isLoading={isLoading}
            page={page}
          />
        }
      />

      {showModalCreate && storeOrder && (
        <ModalStoreOrder
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </>
  )
}

export default StoreOrdersListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Username</th>
      <th>Email/Phone</th>
      <th>Product Image</th>
      <th>Product name</th>
      <th>Points</th>
      <th>Created at</th>
      <th>Status</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const StoreOrdersData = (props: any) => {
  return (
    <>
      {props.storeOrder &&
      props.storeOrder.items &&
      props.storeOrder.items.length > 0 &&
      !props.isLoading ? (
        props.storeOrder.items.map((item: SingleOrder, index: number) => {
          return (
            <tr key={index}>
              <td className="align-middle">{item.id}</td>
              <td className="align-middle">
                {item && item.user && item.user.name ? item.user.name : '/'}
              </td>
              <td className="align-middle">
                {item &&
                item.user &&
                item.user.emails &&
                item.user.emails.length > 0
                  ? item.user.emails[0].email
                  : item &&
                    item.user &&
                    item.user.phones &&
                    item.user.phones.length > 0
                  ? item.user.phones[0].country_code + item.user.phones[0].phone
                  : '/'}
              </td>
              <td className="align-middle">
                {item &&
                item.orderItems[0] &&
                item.orderItems[0].product &&
                item.orderItems[0].product.product_image ? (
                  <img
                    className="storeAvatar"
                    src={`${
                      item && item.orderItems && item.orderItems.length > 0
                        ? item.orderItems[0].product.product_image
                        : '/'
                    }?timestamp${new Date().getTime()}`}
                    alt={
                      item && item.orderItems && item.orderItems.length > 0
                        ? item.orderItems[0].product.name
                        : '/'
                    }
                  />
                ) : (
                  <RxAvatar size={50} />
                )}
              </td>
              <td className="align-middle">
                {item && item.orderItems && item.orderItems.length > 0
                  ? item.orderItems[0].product.name
                  : '/'}
              </td>
              <td className="align-middle">{item.total_points}</td>
              <td className="align-middle">
                {moment(item.created_at).format('DD/MM/YYYY')}
              </td>
              <td className="align-middle">
                {item.status ? item.status : '/'}
              </td>

              <td className="align-middle text-center">
                <StoreTableOrderAction item={item} page={props.page} />
              </td>
            </tr>
          )
        })
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={9} className="text-center text-white-50">
                <h6 className="mb-0">No matching orders found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
