import axios from 'axios'
import { SingleUser, UsersList } from '../../models/users.model'
import api from '../interceptors/api'

//Get all users
const getAllUsers = async (data: any = 1) => {
  const response = await api.post(`/users/list-all`, data)

  return response.data as UsersList
}

const deleteSingleUser = async (id: number) => {
  const response = await api.delete(`/users/delete-user/${id}`)

  return id
}

const updateUser = async (data: any) => {
  const id = data.values.id

  if (data.formData.get('profile_image')) {
    await api.put(`/users/update-user/${id}`, data.formData)
  }

  const response = await api.put(`/users/update-user/${id}`, data.values)

  return response.data
}

const createUser = async (data: any) => {
  const response = await api.post(`users/add-user`, data.values)
  const id = response.data.data.id
  if (data.formData.get('profile_image')) {
    await api.put(`/users/update-user/${id}`, data.formData)
  }
  return response.data
}

//Get all users
const getSingleUser = async (id: any = 1) => {
  const data = {
    limit: 100,
    user_id: +id,
    page: 1,
  }
  const response = await api.post(`/users/list-all`, data)

  return response.data.data.items[0] as SingleUser
}

const usersService = {
  getAllUsers,
  deleteSingleUser,
  updateUser,
  getSingleUser,
  createUser,
}

export default usersService
