import { useState } from "react";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import ReactDatePicker from "react-datepicker";
import ChartForVotes from "../../components/dashboard/ChartForVotes";

function Dashboard() {
  const [dateRange, setDateRange] = useState([
    new Date(),
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
  ]);

  return (
    <div className="conatiner">
      <div className="d-flex justify-content-end">
        <div className="basic-metrics-range">
          <div>
            <div className="yellow text-end pb-2">
              <span className="cursor-poiner text-underline-hover">
                This week
              </span>{" "}
              |{" "}
              <span className="cursor-poiner text-underline-hover">
                This month
              </span>
            </div>
          </div>
          <ReactDatePicker
            selectsRange={true}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={(update: any) => {
              setDateRange(update);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="row">
        <article className="leaderboard col-xl-4 col-md-6 my-3">
          <header>
            <h1 className="leaderboard__title">
              <span className="leaderboard__title--top">Votes</span>
              <span className="leaderboard__title--bottom">
                Top 3 voted videos
              </span>
            </h1>
          </header>

          <main className="leaderboard__profiles">
            <article className="leaderboard__profile">
              <img
                src="https://xtarfactory-staging-content-output.s3.us-west-2.amazonaws.com/thumbnails/thumbs-167_00001.png"
                alt="Mark Zuckerberg"
                className="leaderboard__picture"
              />
              <span className="leaderboard__name">can you do this</span>
              <span className="leaderboard__value">
                35.7<span>B</span>
              </span>
            </article>

            <article className="leaderboard__profile">
              <img
                src="https://xtarfactory-staging-content-output.s3.us-west-2.amazonaws.com/thumbnails/thumbs-167_00001.png"
                alt="Dustin Moskovitz"
                className="leaderboard__picture"
              />
              <span className="leaderboard__name">can you do this</span>
              <span className="leaderboard__value">
                9.9<span>B</span>
              </span>
            </article>

            <article className="leaderboard__profile">
              <img
                src="https://xtarfactory-staging-content-output.s3.us-west-2.amazonaws.com/thumbnails/thumbs-167_00001.png"
                alt="Elizabeth Holmes"
                className="leaderboard__picture"
              />
              <span className="leaderboard__name">can you do this</span>
              <span className="leaderboard__value">
                4.5<span>B</span>
              </span>
            </article>
          </main>
        </article>

        <div className="social-leaderboard col-xl-4 col-md-6 my-3">
          <div id="header">
            <div>
              <h1>SHARED METRICS</h1>
              <h5>TOP 3 SHARED VIDEOS</h5>
            </div>
          </div>
          <div className="shared-metrics">
            <ol>
              <li className="d-flex align-items-center justify-content-between">
                <BiLogoFacebookCircle size={25} />
                <mark>days in football</mark>
                <small>948</small>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <AiFillInstagram size={25} />
                <mark>best singer</mark>
                <small>750</small>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <AiFillTwitterCircle size={25} />
                <mark>something new</mark>
                <small>684</small>
              </li>
            </ol>
          </div>
        </div>

        <article className="leaderboard-views col-xl-4 col-md-6 my-3">
          <header>
            <h1 className="leaderboard-views__title">
              <span className="leaderboard-views__title--top">Views</span>
              <span className="leaderboard-views__title--bottom">
                Most viewd videos
              </span>
            </h1>
          </header>

          <main className="leaderboard-views__profiles">
            <article className="leaderboard-views__profile">
              <div className="leaderboard-views__number-container">
                <span>1</span>
              </div>
              <span className="leaderboard-views__name">can you do this</span>
              <span className="leaderboard-views__value">
                35.7<span>B</span>
              </span>
            </article>

            <article className="leaderboard-views__profile">
              <div className="leaderboard-views__number-container">
                <span>2</span>
              </div>

              <span className="leaderboard-views__name">can you do this</span>
              <span className="leaderboard-views__value">
                9.9<span>B</span>
              </span>
            </article>

            <article className="leaderboard-views__profile">
              <div className="leaderboard-views__number-container">
                <span>3</span>
              </div>

              <span className="leaderboard-views__name">can you do this</span>
              <span className="leaderboard-views__value">
                4.5<span>B</span>
              </span>
            </article>
          </main>
        </article>
      </div>

      <div className="row">
        <div className="col-11">
          <div className="d-flex justify-content-start">
            <div className="basic-metrics-range">
              <div>
                <div className="yellow text-start pb-2">
                  <span className="cursor-poiner text-underline-hover">
                    This week
                  </span>{" "}
                  |{" "}
                  <span className="cursor-poiner text-underline-hover">
                    This month
                  </span>
                </div>
              </div>
              <div className="filters">
                <ReactDatePicker
                  selectsRange={true}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={(update: any) => {
                    setDateRange(update);
                  }}
                  isClearable={false}
                />

                <select className="custom-input">
                  <option style={{ background: "black" }}>video 1</option>
                  <option style={{ background: "black" }}>video 1</option>

                </select>
              </div>
            </div>
          </div>

          <div className="my-3 w-100 h-100">
            <ChartForVotes />
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-11">
          <div className="d-flex justify-content-start">
            <div className="basic-metrics-range">
              <div>
                <div className="yellow text-start pb-2">
                  <span className="cursor-poiner text-underline-hover">
                    This week
                  </span>{" "}
                  |{" "}
                  <span className="cursor-poiner text-underline-hover">
                    This month
                  </span>
                </div>
              </div>
              <div className="filters">
                <ReactDatePicker
                  selectsRange={true}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={(update: any) => {
                    setDateRange(update);
                  }}
                  isClearable={false}
                />

                <select className="custom-input">
                  <option style={{ background: "black" }}>user 1</option>
                </select>
              </div>
            </div>
          </div>

          <div className="my-3 w-100 h-100">
            <ChartForVotes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
