import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import storeOrderService from './storeOrder_service'
import { Data, SingleOrder } from '../../models/storeOrder.models'

interface InitialStateStoreOrder {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  storeOrder: Data | null
  url: string | null
  SingleOrder: SingleOrder | null
  successCRUD: boolean
}

const initialStoreOrderState: InitialStateStoreOrder = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  storeOrder: null,
  url: null,
  successCRUD: false,
  SingleOrder: null,
}

//Get all store orders
export const getAllStoreOrder = createAsyncThunk(
  'storeOrder/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await storeOrderService.getAllStoreOrder(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Delete single store order
export const deleteSingleStoreOrder = createAsyncThunk(
  'storeOrder/delete-single-order',
  async (id: number, thunkAPI) => {
    try {
      return await storeOrderService.deleteSingleStoreOrder(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Update single store order
export const updateSingleStoreOrder = createAsyncThunk(
  'storeOrder/update',
  async (data: any, thunkAPI) => {
    try {
      return await storeOrderService.updateSingleStoreOrder(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const storeOrderSlice = createSlice({
  name: 'storeOrder',
  initialState: initialStoreOrderState,
  reducers: {
    resetStoreOrderState(state) {
      state.isSuccess = false
      state.isError = false
      state.successCRUD = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllStoreOrder.pending, state => {
        state.isLoading = true
        state.storeOrder = null
      })
      .addCase(getAllStoreOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.storeOrder = action.payload.data
      })
      .addCase(getAllStoreOrder.rejected, state => {
        state.storeOrder = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteSingleStoreOrder.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(deleteSingleStoreOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.storeOrder = state.storeOrder && {
          ...state.storeOrder,
          items: state.storeOrder?.items?.filter(x => x.id !== +action.payload),
        }
        state.successCRUD = true
      })
      .addCase(deleteSingleStoreOrder.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreOrder.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.successCRUD = true
      })
      .addCase(updateSingleStoreOrder.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
  },
})

export const { resetStoreOrderState } = storeOrderSlice.actions
export default storeOrderSlice.reducer
