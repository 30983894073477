import React, { useState, useEffect } from 'react'
import CustomTable from '../../components/shared/CustomTable'
import StoreTableProductAction from '../../components/store/StoreTableProductAction'
import ModalStoreProduct from '../../components/store/ModalStoreProduct'
import './store.scss'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useSearchParams } from 'react-router-dom'
import {
  deleteSingleStoreProduct,
  getAllStoreProduct,
  resetStoreProductState,
} from '../../store/storeProducts/storeProduct_slice'
import { SingleProduct } from '../../models/storeProduct.models'
import { RxAvatar } from 'react-icons/rx'
import { toast } from 'react-toastify'
import { getAllStoreCategory } from '../../store/storeCategories/storeCategory_slice'

function StoreProductsListPage() {
  const { storeProduct, isLoading, successCRUD } = useAppSelector(
    state => state.storeProduct
  )
  const { storeCategory } = useAppSelector(state => state.storeCategory)
  const [selectedStoreCategoryId, setSelectedStoreCategoryId] = useState(0)
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const [data, setData] = useState<any>(null)
  const [name, setName] = useState(searchParams.get('search') || '')

  const dispatch = useAppDispatch()

  let dataa: any = {
    limit: 20,
    page: name ? 1 : page,
    store_category: selectedStoreCategoryId,
    search: name,
  }

  const serachByName = (name: string) => {
    setName(name)

    if (name.length > 3 || name === '') {
      dataa = {
        ...dataa,
        search: name,
      }
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllStoreProduct(dataa))
      dispatch(resetStoreProductState())
    }
  }
  useEffect(() => {
    if (successCRUD) {
      toast.success('Success')
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllStoreProduct(dataa))
      dispatch(resetStoreProductState())
    }
  }, [successCRUD])

  useEffect(() => {
    setData(dataa)
    setSearchParams(dataa)
    dispatch(getAllStoreCategory())
    dispatch(getAllStoreProduct(dataa))
  }, [selectedStoreCategoryId])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">Store Products List</h1>
        <button
          onClick={() => setShowModalCreate(true)}
          className="add-new-button"
        >
          Add new product
        </button>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-4 searchBar">
          <input
            name="name"
            id="name"
            className="custom-input"
            placeholder="Search by product name"
            type="text"
            value={name}
            onChange={e => serachByName(e.target.value)}
          />
        </div>
        {storeCategory && (
          <div>
            <label htmlFor="" className="custom-label">
              Category
            </label>
            <select
              name=""
              id=""
              value={selectedStoreCategoryId}
              onChange={e => setSelectedStoreCategoryId(+e.target.value)}
              className="custom-input"
            >
              <option style={{ background: 'black' }} value={0}>
                {' '}
                All
              </option>
              {storeCategory.map(item => (
                <option
                  style={{ background: 'black' }}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <CustomTable
        path="store-products"
        setPage={setPage}
        isLoading={isLoading}
        meta={storeProduct?.meta}
        switchFunction={getAllStoreProduct}
        header={<HeaderTable />}
        searchData={data}
        data={
          <StoreProductsData
            storeProduct={storeProduct}
            isLoading={isLoading}
            page={page}
          />
        }
      />

      {showModalCreate && storeProduct && storeCategory && (
        <ModalStoreProduct
          is_edit={false}
          storeCategory={storeCategory}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </>
  )
}

export default StoreProductsListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Image</th>
      <th>Category</th>
      <th>Product name</th>
      <th>Description</th>
      <th>Points</th>
      <th>Quantity</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const StoreProductsData = (props: any) => {
  const { storeCategory } = useAppSelector(state => state.storeCategory)
  return (
    <>
      {props.storeProduct &&
      props.storeProduct.items &&
      props.storeProduct.items.length > 0 &&
      !props.isLoading ? (
        props.storeProduct.items.map((item: SingleProduct, index: number) => {
          return (
            <tr key={index}>
              <td className="align-middle">{item.id}</td>
              <td className="align-middle">
                {item.product_image ? (
                  <img
                    className="storeAvatar"
                    src={`${
                      item.product_image
                    }?timestamp${new Date().getTime()}`}
                    alt={item.name}
                  />
                ) : (
                  <RxAvatar size={50} />
                )}
              </td>
              <td className="align-middle">
                {' '}
                {item.store_category ? item.store_category.name : '/'}
              </td>
              <td className="align-middle">{item.name ? item.name : '/'}</td>
              <td className="align-middle">
                {item.description ? item.description : '/'}
              </td>
              <td className="align-middle">
                {item.points ? item.points : '/'}
              </td>
              <td className="align-middle">
                {item.quantity ? item.quantity : '/'}
              </td>
              <td className="align-middle text-center">
                <StoreTableProductAction
                  storeCategory={storeCategory}
                  item={item}
                  id={item.id}
                  deleteFunction={deleteSingleStoreProduct}
                  page={props.page}
                />
              </td>
            </tr>
          )
        })
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={8} className="text-center text-white-50">
                <h6 className="mb-0">No matching products found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
