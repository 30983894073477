import React, { useState } from 'react'
import { AiOutlineZoomIn } from 'react-icons/ai'
import ReactPlayer from 'react-player'
import DuelPost from './DuelPost'
import DuelPlayer from './DuelPlayer'

interface Props {
  duels: any
}

function DuelVideo({ duels }: any) {
  const [showVideoModal, setShowVideoModal] = useState(false)

  return (
    <div className="col-xl-2 col-md-4 mb-4">
      <div className="custom-card border-0 shadow align-items-center">
        <div className="image-show" onClick={() => setShowVideoModal(true)}>
          <div id="myImg" className="duel-posts position-relative ">
            <AiOutlineZoomIn className="eye" size={25} color="white" />

            <DuelPost post={duels.posts[0]} />

            <DuelPost post={duels.posts[1]} />
          </div>
        </div>
      </div>

      {showVideoModal && (
        <div id="myModal" className="my-modal">
          <span onClick={() => setShowVideoModal(false)} className="close-img">
            &times;
          </span>
         <DuelPlayer post={duels.posts[0]} />
         <DuelPlayer post={duels.posts[1]} />


          <div id="caption"></div>
        </div>
      )}
    </div>
  )
}

export default DuelVideo
