import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  CreateQuestFormValues,
  Item,
  formatTags,
} from '../../models/quest.model'
import TagsInput from './TagsInput'
import LoadSpinner from '../shared/LoadSpinner'
import BreadCrumbs from '../shared/BreadCrumbs'
import UploadVideoForm from './UploadVideoForm'
import { toast } from 'react-toastify'
import { ChildCategory, ParentCategory } from '../../models/categories.models'
import {
  createNewPost,
  updateSingleArena,
} from '../../store/quests/quest_slice'
import { Value } from 'sass'

interface Props {
  is_edit: boolean
  data?: Item
  id?: string
  page?: number
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  creator_name: Yup.string().nullable(),
  description: Yup.string().required('Description is required'),
  prompt: Yup.string().required('Prompt is required'),
  category: Yup.number().required('Category is required'),
})

function QuestForm({ is_edit, data, id, page }: Props) {
  const {
    url,
    isLoading,
    uploadVideoSuccess,
    id: questId,
  } = useAppSelector(state => state.quests)
  const { categories } = useAppSelector(state => state.categories)

  const [tags, setTags] = useState<any>(
    data && data.post_id && data.post_id.tags
      ? formatTags(data.post_id.tags)
      : []
  )
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      description: data && data.description ? data.description : '',
      title: data && data.name ? data.name : '',
      creator_name: data && data.creator_name ? data.creator_name : '',
      content_type: 1,
      prompt: data && data.prompt ? data.prompt : '',
      category:
        data && data.category && data.category.id
          ? data.category.id
          : categories
          ? categories.data[0]?.children[0]?.id
          : 0,
      scheduled_at:
        data && data.scheduled_at ? data.scheduled_at.slice(0, 16) : '',
      expires_at: data && data.expires_at ? data.expires_at.slice(0, 16) : '',
      tags: [],
      publish_status:
        data &&
        data.scheduled_at &&
        new Date(data.scheduled_at).getFullYear() > 2000
          ? 'private'
          : 'published',
    },
    onSubmit: (values: CreateQuestFormValues) => {
      values.tags = tags

      if (values.scheduled_at !== '' && values.scheduled_at) {
        let nextDate = new Date(values.scheduled_at)
        values.scheduled_at = nextDate.getTime() / 1000

        values.expires_at = new Date(values.expires_at).getTime() / 1000
      }
      if (is_edit) {
        if (values.scheduled_at === '') {
          values.scheduled_at = new Date().getTime() / 1000
        }

        if (values.publish_status === 'published') {
          values.scheduled_at = new Date('1969-12-31').getTime() / 1000
        }

        values.expires_at = new Date(expireDate).getTime() / 1000
        const formikValues = { ...formik.values, name: formik.values.title }
        const dataa = {
          id,
          data: formikValues,
        }

        dispatch(updateSingleArena(dataa)).then(res => {
          toast.success('Updated succesufully')
          window.location.replace(`/quests-list?page${page ? page : 1}`)
        })
      } else {
        dispatch(createNewPost(values))
      }
    },
    validationSchema,
  })

  const caluclateExpiresDate = () => {
    let nextDate = new Date()
    nextDate.setDate(nextDate.getDate() + 7)
    let newDate = nextDate.getTime() / 1000
    formik.values.expires_at = newDate
    return new Date(newDate * 1000).toISOString().slice(0, 16)
  }

  const [expireDate, setExpireDate] = useState(
    data && data.expires_at
      ? data.expires_at.slice(0, 16)
      : caluclateExpiresDate()
  )
  const [scheduledDate, setScheduledDate] = useState(
    data && data.scheduled_at ? data.scheduled_at.slice(0, 16) : ''
  )

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }

  const onScheduleDateChange = (e: any) => {
    formik.values.scheduled_at = e.target.value
    setScheduledDate(e.target.value)
    let nextDate = new Date(e.target.value)

    let nextDatee = nextDate.setDate(nextDate.getDate() + 7) / 1000
    formik.values.expires_at = new Date(nextDatee * 1000).toISOString()
    let newDate = new Date(nextDatee * 1000).toISOString().slice(0, 16)

    setExpireDate(newDate)
  }

  useEffect(() => {
    if (uploadVideoSuccess && questId) {
      toast.success('Video uploaded succesufully')
      setTimeout(() => {
        window.location.replace(`/edit-quest/${questId}`)
      }, 2000)
    }
  }, [dispatch, uploadVideoSuccess, questId])

  const breadCrumbs = [
    { name: `Quests list`, path: `/quests-list?page=${page ? page : 1}` },
  ]

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center flex-wrap-reverse">
        <h1 className="white">
          {is_edit ? `Edit Quest #${id}` : 'Create New Quest'}
        </h1>

        <BreadCrumbs
          breadcrumbs={breadCrumbs}
          activeName={is_edit ? `Edit quest #${id}` : 'New quest'}
        />
      </div>

      <form className="custom-form row">
        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="title" className="custom-label">
              Title
            </label>
            <input
              name="title"
              id="title"
              className="custom-input"
              placeholder="Enter a title"
              type="text"
              value={formik.values.title}
              disabled={url !== null}
              onChange={formik.handleChange}
            />
            {formik.touched.title &&
              formik.errors.title && ( // Display validation error if touched and an error exists
                <div className="error">{formik.errors.title}</div>
              )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="creator_name" className="custom-label">
              Creator Name
            </label>
            <input
              name="creator_name"
              id="creator_name"
              className="custom-input"
              placeholder="Enter creator name"
              type="text"
              value={formik.values.creator_name}
              onChange={formik.handleChange}
              disabled={url !== null}
            />
          </div>
        </div>
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="description" className="custom-label">
              Description
            </label>
            <textarea
              disabled={url !== null}
              name="description"
              id="description"
              className="custom-input"
              rows={6}
              placeholder="Enter a description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="error">{formik.errors.description}</div>
            )}
          </div>
        </div>

        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="prompt" className="custom-label">
              Prompt
            </label>
            <input
              name="prompt"
              id="prompt"
              className="custom-input"
              placeholder="Enter prompt"
              type="text"
              value={formik.values.prompt}
              onChange={formik.handleChange}
              disabled={url !== null}
            />
            {formik.touched.prompt && formik.errors.prompt && (
              <div className="error">{formik.errors.prompt}</div>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="prompt" className="custom-label">
              Category
            </label>
            <select
              name="category"
              id="category"
              className="custom-input"
              value={formik.values.category}
              onChange={formik.handleChange}
              disabled={url !== null}
            >
              {categories &&
                categories.data.map((parent: ParentCategory) => (
                  <optgroup
                    style={{ backgroundColor: 'black' }}
                    key={parent.id}
                    label={parent.name}
                  >
                    {parent.children.map((child: ChildCategory) => (
                      <option
                        style={{ background: 'black' }}
                        key={child.id}
                        value={child.id}
                      >
                        {child.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
            </select>
            {formik.touched.category && formik.errors.category && (
              <div className="error">{formik.errors.category}</div>
            )}
          </div>
        </div>

        <div className="col-xl-12 col-md-12 mb-4">
          <div className="form-group">
            <label className="custom-label">Tags</label>
            <TagsInput disabled={url !== null} tags={tags} setTags={setTags} />
          </div>
        </div>

        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label className="custom-label">Visibility</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="publish_status"
                id="public"
                value="published"
                onChange={formik.handleChange}
                disabled={url !== null}
                checked={
                  is_edit
                    ? formik.values.publish_status === 'published' && true
                    : formik.values.publish_status === 'published'
                    ? true
                    : false
                }
              />
              <label
                className="form-check-label"
                htmlFor="public"
                style={{ marginTop: '4px' }}
              >
                Public
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="publish_status"
                value="private"
                onChange={formik.handleChange}
                id="private"
                checked={
                  is_edit
                    ? formik.values.publish_status === 'private' && true
                    : formik.values.publish_status === 'private'
                    ? true
                    : false
                }
                disabled={url !== null}
              />
              <label
                className="form-check-label"
                htmlFor="private"
                style={{ marginTop: '4px' }}
              >
                Scheduled
              </label>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-md-12 mb-4">
          {formik.values.publish_status === 'private' && (
            <>
              <div className="form-group">
                <label htmlFor="scheduled_at" className="custom-label">
                  Publish date
                </label>
                <input
                  disabled={url !== null}
                  name="scheduled_at"
                  required
                  id="scheduled_at"
                  className="custom-input"
                  placeholder="Pick scheduled date"
                  type="datetime-local"
                  value={scheduledDate}
                  onChange={e => onScheduleDateChange(e)}
                  min={new Date().toISOString().slice(0, 16)}
                />
                {formik.touched.scheduled_at &&
                  formik.values.publish_status === 'private' &&
                  formik.values.scheduled_at === '' && (
                    <div className="error">Scheduled date is required</div>
                  )}
              </div>
            </>
          )}
        </div>

        <div className="col-xl-6 col-md-12 mb-4">
          <div className="form-group">
            <label htmlFor="expires_at" className="custom-label">
              Expire date
            </label>
            <input
              disabled={url !== null}
              name="expires_at"
              required
              id="expires_at"
              className="custom-input"
              placeholder="Pick expire date"
              type="datetime-local"
              value={expireDate}
              onChange={e => setExpireDate(e.target.value)}
              min={new Date().toISOString()}
            />
          </div>
        </div>

        {url === null && (
          <div className="form-group">
            <button
              disabled={isLoading || url !== null}
              onClick={e => handleSubmit(e)}
              type="button"
              className="save-button"
            >
              {isLoading && url === null ? (
                <LoadSpinner
                  width={15}
                  height={15}
                  loadMessage={
                    is_edit ? `Editing quest #${id}... ` : 'Creating quest... '
                  }
                />
              ) : (
                <>{is_edit ? `Edit quest #${id}` : 'Create quest'}</>
              )}
            </button>
          </div>
        )}
      </form>

      {url && !is_edit && <UploadVideoForm />}
    </>
  )
}

export default QuestForm
