import { Button, Modal } from "react-bootstrap";
import { useAppDispatch } from "../../store/store";

interface Props {
  id: number;
  deleteFunction: (id: number) => any;
  onHide: () => void;
  show: boolean;
}

function DeleteModal({ onHide, show, id, deleteFunction }: Props) {
  const dispatch = useAppDispatch();

  const deleteItem = () => {
   dispatch(deleteFunction(id))
    onHide();
  };

  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="modal-delete" closeButton>
        <Modal.Title>Delete item</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-delete">
        Are you sure, you want to delete this item?
      </Modal.Body>
      <Modal.Footer className="modal-delete">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteItem}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
