import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import authReducer from './login/login_slice'
import questReducer from './quests/quest_slice'
import questVideoReducer from './quests/quest-video/quest-video_slice'
import categoryReducer from './categories/categories_slice'
import reportTypesReducer from './reports/types/report-types_slice'
import reportReducer from './reports/reports_slice'
import userReducer from './users/user_slice'
import duelsReducer from './duels/duels_slice'
import storeCategoryReducer from './storeCategories/storeCategory_slice'
import storeProductReducer from './storeProducts/storeProduct_slice'
import storeOrderReducer from './storeOrders/storeOrder_slice'
import leaderboardReducer from './leaderboard/leaderboard_slice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    quests: questReducer,
    questVideo: questVideoReducer,
    categories: categoryReducer,
    reportTypes: reportTypesReducer,
    reports: reportReducer,
    users: userReducer,
    duels: duelsReducer,
    storeCategory: storeCategoryReducer,
    storeProduct: storeProductReducer,
    storeOrder: storeOrderReducer,
    leaderboard: leaderboardReducer,
  },
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
