import { useAppDispatch, useAppSelector } from '../../store/store'
import UserTableActions from '../../components/user/UsersTableActions'
import {
  deleteSingleUser,
  getAllUsers,
  resetUserState,
} from '../../store/users/user_slice'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SingleUser } from '../../models/users.model'
import moment from 'moment'
import { RxAvatar } from 'react-icons/rx'
import { toast } from 'react-toastify'
import '../../components/user/user.scss'
import CustomTable from '../../components/shared/CustomTable'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiReset } from 'react-icons/bi'

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Reset filters
  </Tooltip>
)

function UsersListPage() {
  const { users, isLoading } = useAppSelector(state => state.users)
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const [startDate, setStartDate] = useState(searchParams.get('dateFrom') || '')
  const [endDate, setEndDate] = useState(searchParams.get('dateTo') || '')
  const initialStatus =
    searchParams.get('deletedAt') !== null ? searchParams.get('deletedAt') : ''
  const [status, setStatus] = useState(initialStatus)
  const [name, setName] = useState(searchParams.get('search') || '')
  const [role_id, setRole_id] = useState<any>(
    parseInt(searchParams.get('role_id') || '0')
  )
  const [data, setData] = useState<any>(null)

  const navigate = useNavigate()

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value
    if (newEndDate >= startDate) {
      setEndDate(newEndDate)
    } else {
      toast.error('End date cannot be earlier than the start date.')
    }
  }

  const resetFilters = () => {
    setPage(1)
    setName('')
    setStartDate('')
    setEndDate('')
    setRole_id('')
    setStatus('')
    setData(null)
    navigate('/users?page=1')
  }

  let dataa: any = {
    limit: 20,
    page: role_id || startDate || endDate || name ? 1 : page,
    role_id: +role_id,
    dateFrom: startDate,
    dateTo: endDate,
    deletedAt: status,
    search: name,
  }

  const serachByName = (name: string) => {
    setName(name)

    if (name.length > 3 || name === '') {
      dataa = {
        ...dataa,
        search: name,
      }
      setData(dataa)
      setSearchParams(dataa)
      dispatch(getAllUsers(dataa))
      dispatch(resetUserState())
    }
  }

  useEffect(() => {
    setData(dataa)
    setSearchParams(dataa)
    dispatch(getAllUsers(dataa))
    dispatch(resetUserState())
  }, [role_id, status, startDate, endDate])

  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center">
        <h1 className="white">Users List</h1>
        <Link to="/new-user" className="add-new-button">
          Add new user
        </Link>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-8 searchStatus">
          <div className="col-6 searchBar">
            <input
              name="name"
              id="name"
              className="custom-input"
              placeholder="Search by Username, Email or Phone number"
              type="text"
              value={name}
              onChange={e => serachByName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="" className="custom-label">
              Role
            </label>
            <select
              value={role_id}
              onChange={e => setRole_id(e.target.value)}
              name=""
              id=""
              className="custom-input"
            >
              <option style={{ backgroundColor: 'black' }} value={0}>
                All
              </option>
              <option style={{ backgroundColor: 'black' }} value={1}>
                User
              </option>
              <option style={{ backgroundColor: 'black' }} value={2}>
                Admin
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="" className="custom-label">
              Status
            </label>
            <select
              value={status === null ? '' : status}
              onChange={e => setStatus(e.target.value)}
              name=""
              id=""
              className="custom-input"
            >
              <option style={{ backgroundColor: 'black' }} value={''}>
                Active
              </option>
              <option
                style={{ backgroundColor: 'black' }}
                value={'deactivated'}
              >
                Deactivated
              </option>
            </select>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label htmlFor="" className="custom-label">
              Start date
            </label>
            <input
              name=""
              required
              id=""
              className="custom-input"
              placeholder="Pick start date"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <div className="dash"> - </div>
          <div className="d-flex">
            <div>
              {' '}
              <label htmlFor="" className="custom-label">
                End date
              </label>
              <div className="d-flex align-items-center">
                <input
                  name=""
                  required
                  id=""
                  className="custom-input"
                  placeholder="Pick end date"
                  type="date"
                  value={endDate}
                  onChange={e => onEndDateChange(e)}
                />

                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button onClick={resetFilters} className="delete-button">
                    <BiReset
                      size={30}
                      color="white"
                      className="reset-filters"
                    />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomTable
        path="users"
        setPage={setPage}
        isLoading={isLoading}
        meta={users?.meta}
        switchFunction={getAllUsers}
        header={<HeaderTable />}
        searchData={data}
        data={<UsersData users={users} isLoading={isLoading} page={page} />}
      />
    </>
  )
}
export default UsersListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Profile image</th>
      <th>Username</th>
      <th>Email/Phone</th>
      <th>Created at</th>
      <th>Role</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const UsersData = (props: any) => {
  return (
    <>
      {props.users?.items && props.users.items.length > 0 ? (
        props.users.items.map((item: SingleUser, index: number) => (
          <tr key={index}>
            <td className="align-middle">{item.id}</td>
            <td className="align-middle">
              {item.profile_image ? (
                <img
                  style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                  src={`${item.profile_image}?timestamp${new Date().getTime()}`}
                />
              ) : (
                <RxAvatar size={50} />
              )}
            </td>
            <td className="align-middle">{item.name ? item.name : '/'}</td>
            <td className="align-middle">
              {item.emails.length > 0
                ? item.emails[0].email
                : item.phones.length > 0
                ? item.phones[0].country_code + item.phones[0].phone
                : '/'}
            </td>
            <td className="align-middle">
              {moment(item.created_at).format('DD/MM/YYYY')}
            </td>
            <td className="align-middle">{item.role_id.name}</td>
            <td className="align-middle text-center">
              <UserTableActions
                item={item}
                id={item.id}
                deleteFunction={deleteSingleUser}
                page={props.page}
              />
            </td>
          </tr>
        ))
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={7} className="text-center text-white-50">
                <h6 className="mb-0">No matching users found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
