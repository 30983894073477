import React, { useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiSolidEdit } from 'react-icons/bi'
import DeleteModal from '../shared/DeleteModal'
import ModalStoreProduct from './ModalStoreProduct'
import { SingleProduct } from '../../models/storeProduct.models'
import { SingleCategory } from '../../models/storeCategory.models'

interface Props {
  id: number
  deleteFunction: (id: number) => any
  item: SingleProduct
  page: number
  storeCategory: SingleCategory[] | null
}

function ProductTableAction({
  id,
  deleteFunction,
  item,
  page,
  storeCategory,
}: Props) {
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalCreate, setShowModalCreate] = useState(false)

  return (
    <React.Fragment>
      <div className="loader-container">
        <button onClick={() => setShowModalCreate(true)}>
          <BiSolidEdit color="green" size={25} />
        </button>
        <button onClick={() => setShowModalDelete(true)}>
          <AiFillDelete color="red" size={25} />
        </button>
      </div>

      {showModalDelete && (
        <DeleteModal
          id={id}
          deleteFunction={deleteFunction}
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
        />
      )}
      {showModalCreate && (
        <ModalStoreProduct
          storeCategory={storeCategory}
          item={item}
          is_edit={true}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </React.Fragment>
  )
}

export default ProductTableAction
