import React, { useState } from 'react'
import { Categories, ChildCategory } from '../../models/categories.models';
import { AiFillDelete } from 'react-icons/ai';
import { BiSolidEdit } from 'react-icons/bi';
import DeleteModal from '../shared/DeleteModal';
import { deleteCategoryById } from '../../store/categories/categories_slice';
import ModalCategory from './ModalCategory';


interface Props {
    id: number;
    category: ChildCategory
    categories: Categories

  }

function CategoryTableAction({id, category, categories}: Props) {
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalCreate, setShowModalCreate] = useState(false)

  return (
   <React.Fragment>
     <div className='loader-container'>
        <button onClick={() => setShowModalCreate(true)}  ><BiSolidEdit color='green' size={25} /></button>
        <button onClick={() => setShowModalDelete(true)}><AiFillDelete color='red' size={25} /></button>
    </div>

    {showModalDelete && <DeleteModal id={id} deleteFunction={deleteCategoryById} show={showModalDelete} onHide={() => setShowModalDelete(false)} />}
    {showModalCreate && <ModalCategory categories={categories} is_edit={true} show={showModalCreate} onHide={() => setShowModalCreate(false)} category={category} />}

   </React.Fragment>
  )
}

export default CategoryTableAction