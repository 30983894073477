import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  getAllVideosForQuest,
  resetDeleteSuccess,
} from "../../../store/quests/quest-video/quest-video_slice";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Item } from "../../../models/quest-video.model";
import Pagination from "../../../components/shared/Pagination";
import LoadSpinner from "../../../components/shared/LoadSpinner";
import { AiOutlineZoomIn } from "react-icons/ai";

function QuestVideos() {
  const { questVideos, isLoading } = useAppSelector(
    (state) => state.questVideo
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "1"))
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let data = {
    limit: 20,
    challenge_parent_type_id: id && +id,
    page: page,
  };

  const [showImgModal, setShowImgModal] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(resetDeleteSuccess());
      data.challenge_parent_type_id = +id;
      dispatch(getAllVideosForQuest(data));
    }
  }, [id]);

  const showImageModal = (item: Item) => {
    setUrl(item.thumbnail_url);
    setShowImgModal(true);
  };

  if (isLoading) {
    return <LoadSpinner isFullLoader={true} width={50} height={50} />;
  }
  return (
    <>
      <section className="pb-5">
        <div className="container">
          {questVideos &&
            questVideos?.data.items &&
            questVideos?.data.items.length === 0 && (
              <h6 className="text-center text-white-50">
                There is no videos for quest #{id} yet...
              </h6>
            )}
          <div className="row">
            {questVideos &&
              questVideos.data &&
              questVideos.data.items &&
              questVideos.data.items.map((item: Item, index: number) => (
                <div key={item.id} className="col-xl-2 col-md-4 mb-4">
                  <div className="card border-0 shadow align-items-center">
                    <div
                      onClick={() => showImageModal(item)}
                      className="image-show"
                    >
                      <img
                        id="myImg"
                        src={item.thumbnail_url}
                        className="card-img-top"
                        style={{
                          maxWidth: "200px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                        alt="..."
                      />

                      <AiOutlineZoomIn
                        className="eye"
                        size={25}
                        color="white"
                      />
                    </div>

                    <div className="card-body text-center">
                      <h5 className="card-title mb-0">
                        {item.title || `Video #${item.id}`}
                      </h5>
                      <Link
                        to={`/quest-details/${id}/video-details/${item.id}`}
                        state={{ videoItem: item, page }}
                        style={{ cursor: "pointer" }}
                        className="card-text text-white-50 "
                      >
                        See details
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {questVideos?.data?.meta &&
        !isLoading &&
        questVideos.data.meta.itemCount > 0 && (
          <Pagination
          path={`quest-details/${id}`}
          setPage={setPage}
            meta={questVideos.data.meta}
            switchFunction={getAllVideosForQuest}
            data={data}
          />
        )}

      {showImgModal && (
        <div id="myModal" className="my-modal">
          <span onClick={() => setShowImgModal(false)} className="close-img">
            &times;
          </span>
          <img className="my-modal-content" id="img01" src={url} />
          <div id="caption"></div>
        </div>
      )}
    </>
  );
}

export default QuestVideos;
