import React, { useState } from 'react'
import './user.scss'
import { AiFillDelete } from 'react-icons/ai'

interface Props {
  image: File | null
  setImage: (file: File | null) => void
}

function UploadImageForm({ image, setImage }: Props) {
  const onImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setImage(file)
    } else {
      setImage(null)
    }
  }

  return (
    <div className="imageMain">
      {image ? (
        <div className="selected-image">
          <img
            className="addedImage"
            src={URL.createObjectURL(image)}
            alt={image.name || 'Selected Image'}
          />
          <div className="image-overlay">
            <button
              className="uploadText delImgIcon"
              onClick={() => setImage(null)}
            >
              <AiFillDelete color="red" size={25} />
            </button>
          </div>
        </div>
      ) : (
        <>
          <label htmlFor="fileInput" className="addImage">
            <span className="uploadText">Upload an image</span>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={e => onImageChange(e)}
            />
          </label>
        </>
      )}
    </div>
  )
}

export default UploadImageForm
