import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import usersService from './user_service'
import { Data, SingleUser, UsersList } from '../../models/users.model'

interface InitialStateUsers {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  users: Data | null
  url: string | null
  singleUser: SingleUser | null
}

const initialUsersState: InitialStateUsers = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  users: null,
  url: null,
  singleUser: null,
}

//Get all users
export const getAllUsers = createAsyncThunk(
  'users/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await usersService.getAllUsers(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Get single user
export const getSingleUser = createAsyncThunk(
  'users/get-single',
  async (id: any, thunkAPI) => {
    try {
      return await usersService.getSingleUser(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Delete single user
export const deleteSingleUser = createAsyncThunk(
  'users/delete-single-user',
  async (id: number, thunkAPI) => {
    try {
      return await usersService.deleteSingleUser(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Update single user
export const updateSingleUser = createAsyncThunk(
  'users/update',
  async (data: any, thunkAPI) => {
    try {
      return await usersService.updateUser(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Create New user
export const createNewUser = createAsyncThunk(
  'users/new-user',
  async (data: any, thunkAPI) => {
    try {
      return await usersService.createUser(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    resetUserState(state) {
      state.isSuccess = false
      state.isError = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.pending, state => {
        state.isLoading = true
        state.users = null
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.users = action.payload.data
      })
      .addCase(getAllUsers.rejected, state => {
        state.users = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteSingleUser.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteSingleUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = state.users && {
          ...state.users,
          items: state.users.items.filter(x => x.id !== +action.payload),
        }
      })
      .addCase(deleteSingleUser.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(updateSingleUser.pending, state => {
        state.isLoading = true
      })
      .addCase(updateSingleUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updateSingleUser.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(createNewUser.pending, state => {
        state.isLoading = true
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createNewUser.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(getSingleUser.pending, state => {
        state.isLoading = true
      })
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.singleUser = action.payload
      })
      .addCase(getSingleUser.rejected, state => {
        state.users = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
  },
})

export const { resetUserState } = usersSlice.actions
export default usersSlice.reducer
