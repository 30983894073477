export const hashtagsList = [
    "sports",
    "football",
    "sport",
    "fitness",
    "nfl",
    "basketball",
    "nba",
    "soccer",
    "gym",
    "training",
    "workout",
    "baseball",
    "motivation",
    "athlete",
    "love",
    "follow",
    "fashion",
    "photography",
    "fit",
    "espn",
    "like",
    "life",
    "nike",
    "fitnessmotivation",
    "running",
    "hockey",
    "fun",
    "boxing",
    "sportswear",
    "nhl",
    "health",
    "cricket",
    "explorepage",
    "sportsphotography",
    "nflnews",
    "lifestyle",
    "nflfootball",
    "india",
    "art",
    "ballislife",
    "explore",
    "news",
    "mma",
    "usa",
    "team",
    "ball",
    "tennis",
    "superbowl",
    "nfldraft",
    "collegefootball",
    "style",
    "dance",
    "music",
    "dancer",
    "hiphop",
    "dancing",
    "dancers",
    "party",
    "dj",
    "tiktok",
    "ballet",
    "instagram",
    "instagood",
    "dancelife",
    "viral",
    "dancersofinstagram",
    "trending",
    "choreography",
    "bhfyp",
    "artist",
    "dancechallenge",
    "kpop",
    "happy",
    "dancevideo",
    "housemusic",
    "edm",
    "techno",
    "bollywood",
    "model",
    "house",
    "danza",
    "instadance",
    "repost",
    "video",
    "nightlife",
    "likeforlikes",
    "ballerina",
    "club",
    "photooftheday",
    "dan",
    "song",
    "beautiful",
    "followforfollowback",
    "rap",
    "musica",
    "beauty",
    "makeup",
    "skincare",
    "cute",
    "girl",
    "nature",
    "photo",
    "picoftheday",
    "smile",
    "hair",
    "me",
    "selfie",
    "makeupartist",
    "myself",
    "instadaily",
    "portrait",
    "travel",
    "photoshoot",
    "followme",
    "mua",
    "photographer",
    "lashes",
    "cosmetics",
    "nails",
    "skin",
    "instalike",
    "summer",
    "girls",
    "insta",
    "pretty",
    "loveyourself",
    "fashionblogger",
    "selfcare",
    "skincareroutine",
    "ootd",
    "natural",
    "acting",
    "actor",
    "actress",
    "actorslife",
    "film",
    "theatre",
    "actors",
    "movie",
    "cinema",
    "movies",
    "drama",
    "casting",
    "hollywood",
    "comedy",
    "director",
    "tv",
    "theater",
    "filmmaking",
    "actorlife",
    "modeling",
    "singing",
    "actinglife",
    "audition",
    "act",
    "cinematography",
    "talent",
    "actingclass",
    "filmmaker",
    "performance",
    "teatro",
    "musicaltheatre",
    "stage",
    "castingcall",
    "auditions",
    "broadway",
    "performingarts",
    "actingschool",
    "filming",
    "shortfilm",
    "youtube",
    "actingcoach",
    "castingdirector",
    "livemusic",
    "musicians",
    "live",
    "musical",
    "vocal",
    "instacover",
    "karaoke",
    "covers",
    "indiansingers",
    "acousticcover",
    "guitarist",
    "songcover",
    "pop",
    "concert",
    "musicislife",
    "newmusic",
    "tennisplayer",
    "tennislife",
    "atp",
    "tenis",
    "tenniscourt",
    "tennislove",
    "usopen",
    "wimbledon",
    "wta",
    "tennisfan",
    "tennistraining",
    "instatennis",
    "tenniscoach",
    "federer",
    "atptour",
    "rogerfederer",
    "tennisworld",
    "tennisgirl",
    "tennismatch",
    "tennistime",
    "tennispro",
    "rolandgarros",
    "djokovic",
    "tennisplayers",
    "nadal",
    "australianopen",
    "atpworldtour",
    "novakdjokovic",
    "adidas",
    "babolat",
    "rafaelnadal",
    "tennisacademy",
    "golf",
    "tennislover",
    "tennisfun",
    "grandslam",
    "wilson",
    "tennispractice",
    "tennisvideo",
    "juniortennis",
    "tenniskids",
    "tennisaddict",
    "lovetennis",
    "tennisdrills",
    "tennisball",
    "wilsontennis",
    "colombia",
    "moda",
    "lebronjames",
    "basket",
    "lakers",
    "bball",
    "nbabasketball",
    "basketballneverstops",
    "jordan",
    "hoops",
    "lebron",
    "dunk",
    "basketballislife",
    "nbaplayoffs",
    "basketballtraining",
    "kobebryant",
    "kobe",
    "michaeljordan",
    "basketballplayer"
]