import React from 'react'
import { RxAvatar } from 'react-icons/rx'
import ReactPlayer from 'react-player'

interface Props{
    post: any
}

function DuelPlayer({post}: Props) {
  return (
    <div className="modal-duel-video">
    <ReactPlayer
      url={post.post_url}
      width="100%"
      height="100%"
      loop={true}
      playing={false}
      controls={true}
    />

{post.user.profile_image ?      <img
        src={post.user.profile_image}
        alt=""
        className="user-image"
      /> :    <RxAvatar className='user-image' size={40} />}
    <p className="user-name">
      {post.user.name || post.user.username}
    </p>
  </div>
  )
}

export default DuelPlayer