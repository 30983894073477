import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllReportsTypes } from '../../store/reports/types/report-types_slice'
import { ContentType } from '../../models/shared.models'
import CustomTable from '../../components/shared/CustomTable'
import { Link, useSearchParams } from 'react-router-dom'
import {
  getAllReports,
  resetReportState,
} from '../../store/reports/reports_slice'
import { SingleReports } from '../../models/reports.models'
import moment from 'moment'
import './report.scss'
import { toast } from 'react-toastify'
import { BiReset } from 'react-icons/bi'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Reset filters
  </Tooltip>
)

function ReportListPage() {
  const { reportTypes } = useAppSelector(state => state.reportTypes)
  const { isLoading, reports } = useAppSelector(state => state.reports)
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
  const [startDate, setStartDate] = useState(searchParams.get('dateFrom') || '')
  const [endDate, setEndDate] = useState(searchParams.get('dateTo') || '')
  const [type_id, setType_id] = useState(searchParams.get('type_id') || '')
  const [report_type_id, setReport_type_id] = useState(
    parseInt(searchParams.get('report_type_id') || '0')
  )
  const [data, setData] = useState<any>(null)

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value
    if (newEndDate >= startDate) {
      setEndDate(newEndDate)
    } else {
      toast.error('End date cannot be earlier than the start date.')
    }
  }

  const resetFilters = () => {
    setType_id('')
    setEndDate('')
    setStartDate('')
    setPage(1)
    setReport_type_id(0)
    setData(null)
  }

  useEffect(() => {
    let dataa: any = {
      limit: 20,
      type_id,
      report_type_id,
      dateFrom: startDate,
      dateTo: endDate,
      page: type_id || report_type_id || startDate || endDate ? 1 : page,
    }
    setData(dataa)

    setSearchParams(dataa)
    dispatch(getAllReports(dataa))
    dispatch(getAllReportsTypes())
    dispatch(resetReportState())
  }, [type_id, report_type_id, startDate, endDate])

  return (
    <>
      <div
        className="d-flex justify-content-between mb-5 align-items-center"
        style={{ flexWrap: 'wrap' }}
      >
        <h1 className="white">Reports List</h1>
      </div>
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <div className="col-6 searchStatus">
          <div className="col-4 searchBar">
            <input
              name="type_id"
              id="type_id"
              className="custom-input"
              placeholder="Search by Post ID"
              type="text"
              value={type_id}
              onChange={e => setType_id(e.target.value)}
            />
          </div>
          {reportTypes && (
            <div className="row">
              <div className="col-12">
                <label htmlFor="report_type" className="custom-label">
                  Report type
                </label>
                <select
                  value={report_type_id}
                  onChange={e => setReport_type_id(+e.target.value)}
                  className="custom-input"
                >
                  <option style={{ background: 'black' }} value={0}>
                    All
                  </option>
                  {reportTypes.data.map((reportType: ContentType) => (
                    <option
                      style={{ background: 'black' }}
                      key={reportType.id}
                      value={reportType.id}
                    >
                      {reportType.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label htmlFor="" className="custom-label">
              Start date
            </label>
            <input
              name=""
              required
              id=""
              className="custom-input"
              placeholder="Pick start date"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <div className="dash"> - </div>
          <div className="d-flex">
            <div>
              {' '}
              <label htmlFor="" className="custom-label">
                End date
              </label>
              <div className="d-flex align-items-center">
                <input
                  name=""
                  required
                  id=""
                  className="custom-input"
                  placeholder="Pick end date"
                  type="date"
                  value={endDate}
                  onChange={e => onEndDateChange(e)}
                />

                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button onClick={resetFilters} className="delete-button">
                    <BiReset
                      size={30}
                      color="white"
                      className="reset-filters"
                    />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomTable
        path="reports"
        setPage={setPage}
        isLoading={isLoading}
        meta={reports?.meta}
        switchFunction={getAllReports}
        header={<HeaderTable />}
        searchData={data}
        data={
          <ReportsData
            page={page}
            reports={reports?.items}
            isLoading={isLoading}
          />
        }
      />
    </>
  )
}
export default ReportListPage

const HeaderTable = () => {
  return (
    <>
      <th>ID</th>
      <th>Post ID</th>
      <th>Created at</th>
      <th>Status</th>
      <th className="text-center">Actions</th>
    </>
  )
}

const ReportsData = (props: any) => {
  return (
    <>
      {props.reports && props.reports.length > 0 ? (
        props.reports.map((item: SingleReports, index: number) => (
          <tr key={index}>
            <td className="align-middle">{item.id}</td>
            <td className="align-middle">{item.type_id}</td>
            <td className="align-middle">
              {moment(item.created_at).format('DD/MM/YYYY')}
            </td>
            <td className="align-middle">
              {item.status === 'in_process' ? 'In process' : '/'}
            </td>
            <td className="align-middle text-center">
              <Link
                to={`/reports/${item.id}`}
                state={{ reportItem: item, page: props.page }}
                className="view-item"
              >
                View
              </Link>
            </td>
          </tr>
        ))
      ) : (
        <>
          {!props.isLoading && (
            <tr>
              <td colSpan={5} className="text-center text-white-50">
                <h6 className="mb-0">No matching reports found</h6>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}
