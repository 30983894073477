import { useLocation } from 'react-router-dom'
import QuestForm from '../../components/quest/QuestForm'

function NewQuestPage() {
  const location = useLocation()

  const { page } = (location && location.state) || {}
  return <QuestForm page={page} is_edit={false} />
}

export default NewQuestPage
