import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";

function RootLayout() {
  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />

        <div id="content" className="p-4 p-md-5 pt-5">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default RootLayout;
