import React, { useState } from 'react'
import { BiSolidEdit } from 'react-icons/bi'
import ModalStoreOrder from './ModalStoreOrder'
import { SingleOrder } from '../../models/storeOrder.models'

interface Props {
  item: SingleOrder
  page: number
}

function OrderTableAction({ item, page }: Props) {
  const [showModalCreate, setShowModalCreate] = useState(false)

  return (
    <React.Fragment>
      <div className="loader-container">
        <button onClick={() => setShowModalCreate(true)}>
          <BiSolidEdit color="green" size={25} />
        </button>
      </div>
      {showModalCreate && (
        <ModalStoreOrder
          item={item}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </React.Fragment>
  )
}

export default OrderTableAction
