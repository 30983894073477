import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reportsService from './reports_service'
import {
  PostReport,
  ReportList,
  SingleReports,
} from '../../models/reports.models'
import { PostId } from '../../models/quest.model'

interface InitialStateReports {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  reports: ReportList | null
  successCRUD: boolean
  post: PostReport | null
  singleReport: SingleReports | null
}

const initialReportState: InitialStateReports = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  reports: null,
  successCRUD: false,
  post: null,
  singleReport: null,
}

//Get all reports
export const getAllReports = createAsyncThunk(
  'reports/get-all',
  async (data: any, thunkAPI) => {
    try {
      return await reportsService.getAllReports(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Get single report
export const getSinglereport = createAsyncThunk(
  'reports/get-single',
  async (id: any, thunkAPI) => {
    try {
      return await reportsService.getSingleReport(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Get post for Id
export const getPostForReport = createAsyncThunk(
  'reports/get-post',
  async (id: string, thunkAPI) => {
    try {
      return await reportsService.getPostForReport(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Change status of report
export const changeStatusOfReport = createAsyncThunk(
  'reports/chnage-status',
  async (data: any, thunkAPI) => {
    try {
      return await reportsService.changeStatusOfReport(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const reportSlice = createSlice({
  name: 'reports',
  initialState: initialReportState,
  reducers: {
    resetReportState(state) {
      state.isSuccess = false
      state.successCRUD = false
      state.isError = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllReports.pending, state => {
        state.reports = null
        state.isLoading = true
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.isLoading = false
        state.reports = action.payload
      })
      .addCase(getAllReports.rejected, state => {
        state.reports = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(getPostForReport.pending, state => {
        state.isLoading = true
      })
      .addCase(getPostForReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.post = action.payload
      })
      .addCase(getPostForReport.rejected, state => {
        state.post = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(changeStatusOfReport.pending, state => {
        state.isLoading = true
      })
      .addCase(changeStatusOfReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.successCRUD = true
        // state.post = action.payload;
      })
      .addCase(changeStatusOfReport.rejected, state => {
        state.post = null
        state.successCRUD = false
        state.isLoading = false
        state.isError = true
      })

      .addCase(getSinglereport.pending, state => {
        state.isLoading = true
      })
      .addCase(getSinglereport.fulfilled, (state, action) => {
        state.isLoading = false
        state.singleReport = action.payload
      })
      .addCase(getSinglereport.rejected, state => {
        state.singleReport = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
  },
})

export const { resetReportState } = reportSlice.actions
export default reportSlice.reducer
