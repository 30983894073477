import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SingleCategory } from '../../models/storeCategory.models'
import storeCategoryService from './storeCategory_service'

interface InitialStateStoreCategory {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  storeCategory: SingleCategory[] | null
  url: string | null
  SingleCategory: SingleCategory | null
  successCRUD: boolean
}

const initialStoreCategoryState: InitialStateStoreCategory = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  storeCategory: null,
  url: null,
  successCRUD: false,
  SingleCategory: null,
}

//Get all store category
export const getAllStoreCategory = createAsyncThunk(
  'storeCategory/get-all',
  async (_, thunkAPI) => {
    try {
      return await storeCategoryService.getAllStoreCategory()
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Delete single store category
export const deleteSingleStoreCategory = createAsyncThunk(
  'storeCategory/delete-single-category',
  async (id: number, thunkAPI) => {
    try {
      return await storeCategoryService.deleteSingleStoreCategory(id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Update single store category
export const updateSingleStoreCategory = createAsyncThunk(
  'storeCategory/update',
  async (data: any, thunkAPI) => {
    try {
      return await storeCategoryService.updateSingleStoreCategory(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Create New store category
export const createStoreCategory = createAsyncThunk(
  'storeCategory/new-Category',
  async (data: any, thunkAPI) => {
    try {
      return await storeCategoryService.createStoreCategory(data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const storeCategorySlice = createSlice({
  name: 'storeCategory',
  initialState: initialStoreCategoryState,
  reducers: {
    resetStoreCategoryState(state) {
      state.isSuccess = false
      state.isError = false
      state.successCRUD = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllStoreCategory.pending, state => {
        state.isLoading = true
        state.storeCategory = null
      })
      .addCase(getAllStoreCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.storeCategory = action.payload.data
      })
      .addCase(getAllStoreCategory.rejected, state => {
        state.storeCategory = null
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteSingleStoreCategory.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(deleteSingleStoreCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        ;(state.storeCategory =
          state.storeCategory &&
          state.storeCategory.filter(x => x.id !== +action.payload)),
          (state.successCRUD = true)
      })
      .addCase(deleteSingleStoreCategory.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreCategory.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(updateSingleStoreCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.successCRUD = true
      })
      .addCase(updateSingleStoreCategory.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })

      .addCase(createStoreCategory.pending, state => {
        state.isLoading = true
        state.successCRUD = false
      })
      .addCase(createStoreCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.successCRUD = true
      })
      .addCase(createStoreCategory.rejected, state => {
        state.isSuccess = false
        state.isLoading = false
        state.isError = true
        state.successCRUD = false
      })
  },
})

export const { resetStoreCategoryState } = storeCategorySlice.actions
export default storeCategorySlice.reducer
