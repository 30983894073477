import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../interceptors/api'
import { UserLoginresponse } from '../../models/user.model'
import { User } from '../../models/shared.models'

interface InitialStateAuth {
  isAuthenticated: boolean
  isEmailValid: boolean
  user: User | null
  isLoading: boolean
  isAdmin: boolean | null
}

const initialAuthState: InitialStateAuth = {
  isAuthenticated: JSON.parse(localStorage.getItem('xtar') || 'null')
    ? true
    : false,
  user: JSON.parse(localStorage.getItem('xtar') || 'null')
    ? JSON.parse(localStorage.getItem('xtar') || 'null')
    : null,
  isEmailValid: false,
  isLoading: false,
  isAdmin: null,
}

export let bearerToken = initialAuthState.user

export const userEmailLogin = createAsyncThunk<any, any>(
  'user/login-email',
  async (credentials: any, thunkAPI) => {
    try {
      const response = await api.post<UserLoginresponse>(
        `/auth/email/login`,
        credentials
      )

      return response.data as UserLoginresponse
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue('Failed to fetch issues.')
    }
  }
)

export const userEmailVerifyOtp = createAsyncThunk<any, any>(
  'user/login-verify-otp-email',
  async (credentials: any, thunkAPI) => {
    try {
      const response = await api.post(`/auth/email/verify/otp`, credentials)

      if (response.data.data.user.role_id.id === 2) {
        bearerToken = response.data.data.token
        localStorage.setItem('xtar', JSON.stringify(response.data.data.token))
      }

      return response.data as UserLoginresponse
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue('Failed to fetch issues.')
    }
  }
)

export const userPhoneLogin = createAsyncThunk<any, any>(
  'user/login-phone',
  async (credentials: any, thunkAPI) => {
    try {
      const response = await api.post(`/auth/mobile/login`, credentials)

      return response.data as UserLoginresponse
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue('Failed to fetch issues.')
    }
  }
)

export const userPhoneVerifyOtp = createAsyncThunk<any, any>(
  'user/login-verify-otp-phone',
  async (credentials: any, thunkAPI) => {
    try {
      const response = await api.post(`/auth/mobile/verify/otp`, credentials)

      if (response.data.data.user.role_id.id === 2) {
        bearerToken = response.data.data.token
        localStorage.setItem('xtar', JSON.stringify(response.data.data.token))
      }

      return response.data as UserLoginresponse
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue('Failed to fetch issues.')
    }
  }
)

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    logout(state) {
      localStorage.removeItem('xtar')
      state.isAuthenticated = false
      state.isEmailValid = false
      state.isLoading = false
      state.user = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userEmailLogin.pending, state => {
        state.isEmailValid = false
        state.user = null
        state.isLoading = true
      })
      .addCase(userEmailLogin.fulfilled, (state, action) => {
        state.isEmailValid = true
        state.user = action.payload.data.user
        state.isLoading = false
      })
      .addCase(userEmailLogin.rejected, state => {
        state.user = null
        state.isLoading = false
        state.isEmailValid = false
      })

      .addCase(userEmailVerifyOtp.pending, state => {
        state.isAuthenticated = false
        state.user = null
      })
      .addCase(userEmailVerifyOtp.fulfilled, (state, action) => {
        state.isAuthenticated =
          action.payload.data.user.role_id.id === 2 ? true : false
        state.isAdmin = action.payload.data.user.role_id.id === 2 ? true : false
        state.user = action.payload.data.user
      })
      .addCase(userEmailVerifyOtp.rejected, state => {
        state.user = null
        state.isAdmin = false

        state.isAuthenticated = false
      })

      .addCase(userPhoneLogin.pending, state => {
        state.isEmailValid = false
        state.user = null
        state.isLoading = true
      })
      .addCase(userPhoneLogin.fulfilled, (state, action) => {
        state.isEmailValid = true
        state.user = action.payload.data.user
        state.isLoading = false
      })
      .addCase(userPhoneLogin.rejected, state => {
        state.user = null
        state.isLoading = false
        state.isEmailValid = false
      })

      .addCase(userPhoneVerifyOtp.pending, state => {
        state.isAuthenticated = false
        state.user = null
      })
      .addCase(userPhoneVerifyOtp.fulfilled, (state, action) => {
        action.payload.data.user.role_id.id === 2 ? true : false
        state.isAdmin = action.payload.data.user.role_id.id === 2 ? true : false
        state.user = action.payload.data.user
      })
      .addCase(userPhoneVerifyOtp.rejected, state => {
        state.user = null
        state.isAdmin = false

        state.isAuthenticated = false
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
