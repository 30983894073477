import React, { useState } from 'react'
import '../../pages/login/login.scss'

import { FcGoogle } from 'react-icons/fc'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { userEmailLogin, userPhoneLogin } from '../../store/login/login_slice'
import LoadSpinner from '../shared/LoadSpinner'
import countryCodes from '../../countryCodes.json'

interface Props {
  email: string
  setEmail: (e: any) => void
  phone: string
  setPhone: (e: any) => void
  countryCode: string
  setCountryCode: (e: any) => void
}

function LoginForm({
  email,
  setEmail,
  phone,
  setPhone,
  countryCode,
  setCountryCode,
}: Props) {
  const [loginWay, setLoginWay] = useState('email')
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector(state => state.auth)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (loginWay === 'email') {
      dispatch(userEmailLogin({ email }))
    } else {
      dispatch(userPhoneLogin({ mobile: phone, country_code: countryCode }))
    }
  }

  return (
    <React.Fragment>
      <p className="text-center mb-3 mt-2">ADMIN PANEL</p>
      <div className="row mx-auto ">
        <div className="col-6">
          <div className="fab">
            <FcGoogle size={25} />
          </div>
        </div>
        <div className="col-6">
          <div className="fab">
            {loginWay === 'email' ? (
              <BsTelephoneFill
                onClick={() => setLoginWay('phone')}
                color="3D14FF"
                size={25}
              />
            ) : (
              <MdEmail
                size={25}
                color="3D14FF"
                onClick={() => setLoginWay('email')}
              />
            )}
          </div>
        </div>
      </div>
      <div className="division">
        <div className="row">
          <div className="col-3">
            <div className="line l"></div>
          </div>
          <div className="col-6 loginway">
            <span>USE {loginWay}</span>
          </div>
          <div className="col-3">
            <div className="line r"></div>
          </div>
        </div>
      </div>

      <form onSubmit={e => handleSubmit(e)} className="myform">
        {loginWay === 'phone' && (
          <div className="form-group">
            <select
              name="cCode"
              id="cCode"
              className="custom-input"
              onChange={e => setCountryCode(e.target.value)}
              value={countryCode}
            >
              {countryCodes.countries.map((country, index) => (
                <option
                  style={{ backgroundColor: 'black' }}
                  key={index}
                  value={country.dial_code}
                >
                  {country.name} ({country.dial_code})
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group">
          <input
            required={true}
            value={loginWay === 'email' ? email : phone}
            onChange={e => {
              loginWay === 'email'
                ? setEmail(e.target.value)
                : setPhone(e.target.value)
            }}
            className="form-control"
            placeholder={loginWay}
          />
        </div>

        <div className="form-group mt-3">
          <button
            disabled={email === '' && phone === '' && isLoading}
            type="submit"
            className="btn  btn-primary form-control"
          >
            <small>
              {isLoading ? (
                <LoadSpinner
                  loadMessage="Signing in... "
                  width={14}
                  height={14}
                />
              ) : (
                'Sign in'
              )}
            </small>
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default LoginForm
