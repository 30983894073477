import React from "react";

interface Props {
  loadMessage?: string;
  width: number;
  height: number;
  isFullLoader?: boolean;
}

function LoadSpinner({ loadMessage, width, height, isFullLoader }: Props) {
  return (
    <div
      className={`${
        isFullLoader ? "full-screen-loader loader-container" : 'loader-container'
      }`}
    >
      <span>{loadMessage}</span>
      <div
        className="loader"
        style={{ width: `${width}px`, height: `${height}px` }}
      ></div>
    </div>
  );
}

export default LoadSpinner;
