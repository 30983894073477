import React, { useEffect } from 'react'
import './style/global.scss'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Login from './pages/login/Login'
import RootLayout from './pages/Root'
import Error404 from './pages/Error404'
import { useAppDispatch, useAppSelector } from './store/store'
import QuestListPage from './pages/quest/QuestListPage'
import NewQuestPage from './pages/quest/NewQuestPage'
import EditQuestPage from './pages/quest/EditQuestPage'
import QuestVideos from './pages/quest/Tabs/QuestVideos'
import VideoDetails from './pages/quest/video/VideoDetails'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { getAllCategories } from './store/categories/categories_slice'
import CategoryListPage from './pages/categories/CategoryListPage'
import ReportListPage from './pages/reports/ReportListPage'
import ReportDetailsPage from './pages/reports/ReportDetailsPage'
import UsersListPage from './pages/Users/UsersListPage'
import NewUserPage from './pages/Users/NewUserPage'
import EditUserPage from './pages/Users/EditUserPage'
import Dashboard from './pages/dashboard/Dashboard'
import Leaderboard from './pages/leaderboard/Leaderboard'
import StoreCategory from './pages/store/StoreCategoryListPage'
import StoreOrders from './pages/store/StoreOrdersListPage'
import StoreProducts from './pages/store/StoreProductsListPage'
import { hashtagsList } from './data/hashtags'
import QuestDetailsPage from './pages/quest/QuestDetailsPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <Error404 />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/quests-list',
        element: <QuestListPage />,
      },
      {
        path: '/new-quest',
        element: <NewQuestPage />,
      },
      {
        path: '/edit-quest/:id',
        element: <EditQuestPage />,
      },
      {
        path: '/quest-details/:id',
        element: <QuestDetailsPage />,
      },
      {
        path: '/quest-details/:id/video-details/:videoId',
        element: <VideoDetails />,
      },
      {
        path: '/categories-list',
        element: <CategoryListPage />,
      },
      {
        path: '/reports',
        element: <ReportListPage />,
      },
      {
        path: '/reports/:id',
        element: <ReportDetailsPage />,
      },
      {
        path: '/users',
        element: <UsersListPage />,
      },
      {
        path: '/new-user',
        element: <NewUserPage />,
      },
      {
        path: '/edit-user/:id',
        element: <EditUserPage />,
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/leaderboard',
        element: <Leaderboard />,
      },
      {
        path: '/store-categories',
        element: <StoreCategory />,
      },
      {
        path: '/store-orders',
        element: <StoreOrders />,
      },
      {
        path: '/store-products',
        element: <StoreProducts />,
      },
    ],
  },
])

function App() {
  const isAuth = useAppSelector((state: any) => state.auth.isAuthenticated)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAuth) {
      dispatch(getAllCategories())
    }
  }, [])

  return (
    <React.Fragment>
      {!isAuth && <Login />}
      {isAuth && (
        <>
          <RouterProvider router={router} />
        </>
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  )
}

export default App
