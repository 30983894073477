import { Button, Modal } from 'react-bootstrap'
import UploadImageForm from '../user/UploadImageForm'
import { useState } from 'react'
import '../../pages/store/store.scss'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { SingleCategory } from '../../models/storeCategory.models'
import { useAppDispatch } from '../../store/store'
import {
  createStoreCategory,
  updateSingleStoreCategory,
} from '../../store/storeCategories/storeCategory_slice'
import { AiFillDelete } from 'react-icons/ai'

interface Props {
  onHide: () => void
  show: boolean
  is_edit: boolean
  item?: SingleCategory
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})
function ModalStoreCategory({ onHide, show, is_edit, item }: Props) {
  const [image, setImage] = useState<File | null>(null)
  const [showImage, setShowImage] = useState(false)

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      name: item && item.name ? item.name : '',
      description: item && item.description ? item.description : '',
      category_image: item && item.category_image ? item.category_image : '',
    },
    onSubmit: (values: any) => {
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('description', values.description)
      if (image) {
        formData.append('category_image', image)
      }
      if (is_edit && item && item.id) {
        formData.append('id', item.id.toString())
        dispatch(updateSingleStoreCategory(formData))
      } else {
        dispatch(createStoreCategory(formData)).then((res: any) => {
          onHide()
        })
      }
    },
    validationSchema,
  })

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e)
  }
  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="modal-delete" closeButton>
        <Modal.Title>
          {is_edit ? `Edit category` : 'Create new category'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-delete">
        <form
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
          className="custom-form row"
        >
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="uploadImage">
              {formik.values.category_image && !showImage ? (
                <div className="selected-image">
                  <img
                    className="addedImage"
                    src={`${
                      formik.values.category_image
                    }?timestamp=${new Date().getTime()}`}
                    alt="Category image"
                  />
                  <div
                    className="image-overlay"
                    onClick={() => {
                      setShowImage(true)
                    }}
                  >
                    <button className="uploadText delImgIcon">
                      <AiFillDelete color="red" size={25} />
                    </button>
                  </div>
                </div>
              ) : (
                <UploadImageForm image={image} setImage={setImage} />
              )}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="custom-label">
                Category name
              </label>
              <input
                name="name"
                id="name"
                className="custom-input"
                placeholder="Enter category name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error">{formik.errors.name.toString()}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="description" className="custom-label">
                Description
              </label>
              <input
                name="description"
                id="description"
                className="custom-input"
                placeholder="Enter a description"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="error">
                  {formik.errors.description.toString()}
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-delete">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={(e: any) => {
            handleSubmit(e)
          }}
          variant="danger"
        >
          {is_edit ? 'Edit' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalStoreCategory
