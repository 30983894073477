import React, { useEffect, useState, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllQuests } from '../../store/quests/quest_slice'
import './leaderboard.scss'
import {
  getLeaderboard,
  resetLeaderboardState,
} from '../../store/leaderboard/leaderboard_slice'
import Winners from '../../components/leaderboard/LeaderboardWinners'
import Participants from '../../components/leaderboard/LeaderboardParticipants'
import LoadSpinner from '../../components/shared/LoadSpinner'
import { useSearchParams } from 'react-router-dom'

function Leaderboard() {
  const { quests } = useAppSelector(state => state.quests)
  const { isLoading, leaderboard } = useAppSelector(state => state.leaderboard)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))

  const defaultSelectedQuestId =
    (quests?.items?.length ?? 0) > 0 ? quests?.items[0].id : ''
  const [selectedQuestId, setSelectedQuestId] = useState(defaultSelectedQuestId)

  const dispatch = useAppDispatch()
  const limitRef = useRef<number>(10)

  useEffect(() => {
    if (quests && quests.items && quests.items.length > 0 && !selectedQuestId) {
      setSelectedQuestId(quests.items[0].id)
    }
    setPage(1)
    if (selectedQuestId) {
      dispatch(getLeaderboard({ id: selectedQuestId, limit: 10 }))
    }
    dispatch(resetLeaderboardState())
    const intervalId = setInterval(() => {
      if (selectedQuestId) {
        dispatch(
          getLeaderboard({ id: selectedQuestId, limit: limitRef.current })
        )
      }
    }, 30000)
    return () => clearInterval(intervalId)
  }, [dispatch, selectedQuestId, quests])

  useEffect(() => {
    dispatch(getAllQuests({}))
  }, [dispatch])

  const loadMore = () => {
    const newLimit = (page + 1) * 10
    limitRef.current = newLimit
    dispatch(
      getLeaderboard({
        id: selectedQuestId,
        limit: newLimit,
      })
    )
    setPage(page + 1)
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center">
        <h1 className="white">Leaderboard</h1>
        <div className="col-3">
          {quests && (
            <div className="row">
              <div className="col-12">
                <label htmlFor="selected_quest" className="custom-label">
                  Select Quest
                </label>
                <select
                  value={selectedQuestId}
                  onChange={e => setSelectedQuestId(e.target.value)}
                  className="custom-input"
                  id="selected_quest"
                >
                  {quests.items.map(quest => (
                    <option
                      style={{ background: 'black' }}
                      key={quest.id}
                      value={quest.id}
                    >
                      {quest.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <LoadSpinner width={55} height={55} />
      ) : (
        <>
          <Winners />
          <Participants />
        </>
      )}
      {leaderboard &&
      leaderboard.meta &&
      leaderboard.meta.totalItems &&
      leaderboard.meta.totalItems > 10 ? (
        <>
          <div className="loadMoreDiv">
            <button
              onClick={loadMore}
              disabled={isLoading}
              className="loadMoreButton"
              style={{
                display:
                  leaderboard.meta.totalItems > limitRef.current
                    ? 'block'
                    : 'none',
              }}
            >
              {isLoading ? null : 'Load more'}
            </button>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Leaderboard
