import React, { useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiSolidEdit } from 'react-icons/bi'
import DeleteModal from '../shared/DeleteModal'
import ModalStoreCategory from './ModalStoreCategory'
import { SingleCategory } from '../../models/storeCategory.models'

interface Props {
  id: number
  deleteFunction: (id: number) => any
  item: SingleCategory
  page: number
}

function CategoryTableAction({ id, deleteFunction, item, page }: Props) {
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalCreate, setShowModalCreate] = useState(false)

  return (
    <React.Fragment>
      <div className="loader-container">
        <button onClick={() => setShowModalCreate(true)}>
          <BiSolidEdit color="green" size={25} />
        </button>
        <button onClick={() => setShowModalDelete(true)}>
          <AiFillDelete color="red" size={25} />
        </button>
      </div>

      {showModalDelete && (
        <DeleteModal
          id={id}
          deleteFunction={deleteFunction}
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
        />
      )}
      {showModalCreate && (
        <ModalStoreCategory
          item={item}
          is_edit={true}
          show={showModalCreate}
          onHide={() => setShowModalCreate(false)}
        />
      )}
    </React.Fragment>
  )
}

export default CategoryTableAction
