import axios from "axios";
import { CreateQuestFormValues, Item } from "../../models/quest.model";
import api from "../interceptors/api";

//Get all quests
const getAllQuests = async (data: any = 1) => {
  
  const response = await api.post(`/arena/index`, data);
  return response.data;
};

const createNewQuest = async (data: CreateQuestFormValues) => {
  const response = await api.post(`/posts`, data);
  let id = null

  if (response.data && response.data.data.post) {
    const post = response.data.data.post;
    const questData = {
      post_id: post.id,
      status: 1,
      challenge_type: 1,
      name: data.title,
      creator_name: data.creator_name ?? "Xtar",
      description: data.description,
      expires_at: data.expires_at !== "" ? data.expires_at : "",
      category: +data.category,
      prompt: data.prompt,
      scheduled_at:  data.scheduled_at !== "" ? data.scheduled_at : ""
    };

    
    const responseQuest = await api.post(`/arena`, questData);
    id = responseQuest.data.data.id;

  }

  console.log("id", {data: response.data, id})


  return {data: response.data, id};
};

const uploadVideo = async (params: any) => {
  let data = params.get("video");
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: params.get("url"),
    headers: {
      "Content-Type": "video/mp4",
    },
    data: data,
  };
  const response = await axios.request(config);

  return response.data;
};

const getSingleArena = async (id: string) => {
  const data = {
    arena_id: +id,
    limit: 5,
    page: 1
  }
  const response = await api.post(`/arena/index`, data);

  return response.data.data.items[0] as Item;
}


const deleteSingleArena = async (id: number) => {
  const response = await api.delete(`/arena/${id}`);

  return id;
}

const updateQuest = async (data: any) => {
  const response = await api.patch(`/arena/${data.id}`, data.data);

  return response.data;
}

const questsService = {
  getAllQuests,
  createNewQuest,
  uploadVideo,
  getSingleArena,
  deleteSingleArena,
  updateQuest
};

export default questsService;
